import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 29 32" fill="currentColor" className={className}>
			<rect x="12.1393" y="13.8242" width="6.3" height="1.152" />
			<path d="M12.1393 17.2798V18.4318H17.0361L17.5393 17.2798H12.1393Z" />
			<path d="M12.9109 9.90625L8.02457 14.7925L6.79042 13.5583L5.97466 14.3741L8.02335 16.4229L9.46796 14.9784V18.0358H4.85996V12.2759H8.31596L9.46796 11.1239H3.70796L3.70789 19.1879H10.62V13.8264L13.7255 10.7209L12.9109 9.90625Z" />
			<path d="M3.62256 20.7358V21.8878H12.2421L13.5226 20.7358H3.62256Z" />
			<rect x="3.62256" y="24.1919" width="6.3" height="1.152" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26.8261 10.6906L28.4553 12.3196C28.9049 12.7693 28.9049 13.4991 28.4552 13.9488L23.0473 19.3568V28.7999C23.0473 30.0723 22.0158 31.1039 20.7433 31.1039H2.31132C1.03887 31.1039 0.00732422 30.0723 0.00732422 28.7999V8.06386C0.00732422 6.79133 1.03887 5.75986 2.31132 5.75986H4.81282C5.18232 4.71442 6.04006 3.89974 7.11264 3.59064C7.64364 3.43764 8.05289 3.0299 8.20568 2.49883C8.62068 1.05574 9.95067 0 11.5273 0C13.1041 0 14.4341 1.05574 14.8492 2.49905C15.0018 3.02947 15.41 3.43771 15.9404 3.59021C17.0134 3.89873 17.8705 4.71427 18.2404 5.75986H20.7434C22.0159 5.75986 23.0474 6.79133 23.0474 8.06386V11.1825L21.8954 12.3425V8.06386C21.8954 7.42759 21.3797 6.91186 20.7434 6.91186L18.4394 6.91193V9.21593H4.61561V6.91186H2.3114C1.67513 6.91186 1.1594 7.42759 1.1594 8.06386V28.7999C1.1594 29.436 1.67513 29.9519 2.3114 29.9519H20.7434C21.3797 29.9519 21.8954 29.436 21.8954 28.7999V20.5088L15.9133 26.4907L15.5061 26.493C15.3578 26.4939 15.2541 26.4944 15.1504 26.4949C14.9093 26.4958 14.6682 26.4958 13.8682 26.4957H13.8661H13.8639H13.8616L12.6794 26.4956V23.208L25.197 10.6905C25.6466 10.2408 26.3765 10.2409 26.8261 10.6906ZM7.43153 4.69771C6.45968 4.97779 5.77539 5.88067 5.76747 6.89342V8.06393H17.2873V6.91193C17.2873 5.89003 16.6024 4.97938 15.6219 4.69728C14.7076 4.43441 14.0048 3.73162 13.7419 2.81736C13.4599 1.83672 12.5493 1.152 11.5273 1.152C10.5054 1.152 9.59477 1.83672 9.31275 2.81736C9.04995 3.73104 8.34665 4.43405 7.43153 4.69771ZM15.4333 25.3415C15.1346 25.3431 15.0123 25.3436 14.5989 25.3438L13.8313 24.5762V23.6852L14.6073 22.9092L16.2365 24.5383L15.4333 25.3415ZM15.4219 22.0946L17.051 23.7238L24.3823 16.3926L22.7532 14.7634L15.4219 22.0946ZM25.1969 15.5779L23.5677 13.9488L24.3823 13.1342L26.0116 14.7633L25.1969 15.5779ZM26.0115 11.5051L25.1969 12.3196L26.8261 13.9488L27.6407 13.1342L26.0115 11.5051Z"
			/>
			<path d="M11.5447 4.3198C12.0218 4.3198 12.4087 3.93297 12.4087 3.4558C12.4087 2.97862 12.0218 2.5918 11.5447 2.5918C11.0675 2.5918 10.6807 2.97862 10.6807 3.4558C10.6807 3.93297 11.0675 4.3198 11.5447 4.3198Z" />
		</svg>
	);
}
