import React from "react";
import Carousel, { Modal, ModalGateway } from "react-images";
import { XFileRef } from "@external-types/file";

interface Props {
	files: XFileRef[];
	scopeId: number;
}

export function ImagesGallery({ files, scopeId }: Props) {
	const [modalIsOpen, setModalIsOpen] = React.useState(false);
	const [modalPosition, setModalPosition] = React.useState(0);

	function closeModal() {
		setModalIsOpen(false);
	}

	function openModal(index: number) {
		return function() {
			setModalPosition(index);
			setModalIsOpen(true);
		};
	}

	const images = files.map(file => ({
		source: `/api/files/s/${scopeId}/${file.id}/content`,
	}));

	return (
		<>
			{files.map((file, index) => (
				<img
					src={`/api/files/s/${scopeId}/${file.id}/content`}
					height={142}
					key={file.id}
					onClick={openModal(index)}
					style={{ marginRight: 10, cursor: "pointer" }}
				/>
			))}

			<ModalGateway>
				{modalIsOpen ? (
					<Modal onClose={closeModal}>
						<Carousel views={images} currentIndex={modalPosition} />
					</Modal>
				) : null}
			</ModalGateway>
		</>
	);
}
