import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { Path } from "@helpers/navigation";
import { Overlay } from "@app-components/controls/overlay";
import { PanelSection } from "@app-components/panel-test/panel-section";

import { AppHeader } from "@rossotr/components/app-header";
import { OrgHeader } from "@rossotr/components/org-header";
import { OrgBreadcrumb, OrgNavigation, SectionItemId } from "@rossotr/components/org-navigation";
import { OrgModel } from "@rossotr/models/org-model";

import { StoreContext } from "../../context";
import { BizByChartPanel } from "./biz-by-chart-panel";
import { BizProductionPanel } from "./biz-production-panel";
import { BizEducationPanel } from "./biz-education-panel";
import { BizResultsPanel } from "./biz-results-panel";
import { BizIndicatorsPanel } from "./biz-indicators-panel";
import { ScopePanel } from "../../../../../src/components/scope-panel";

interface Props {
	org: OrgModel;
}

const Content = observer(({ org }: Props) => {
	if (org.orgBiz === null) {
		return null;
	}

	return (
		<>
			{org.orgBiz.bizByCharterSection !== null && <BizByChartPanel section={org.orgBiz.bizByCharterSection} />}
			{org.orgBiz.bizEducationSection !== null && <BizEducationPanel section={org.orgBiz.bizEducationSection} />}
			{org.orgBiz.bizProductionSection !== null && (
				<BizProductionPanel section={org.orgBiz.bizProductionSection} />
			)}
			{org.orgBiz.bizResultsSection !== null && <BizResultsPanel section={org.orgBiz.bizResultsSection} />}
			{org.orgBiz.bizIndicatorsSection !== null && (
				<BizIndicatorsPanel section={org.orgBiz.bizIndicatorsSection} />
			)}
			{org.orgBiz.bizOtherInfoSection !== null && (
				<ScopePanel section={org.orgBiz.bizOtherInfoSection}>
					<PanelSection>
						{org.orgBiz.bizOtherInfoSection.value.map((str, index) => (
							<p key={index}>{str}</p>
						))}
					</PanelSection>
				</ScopePanel>
			)}
		</>
	);
});

export const OrgBiz = observer(function() {
	const [fetched, setFetched] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	async function fetch() {
		await orgStore.fetchOrg(orgId);

		if (orgStore.currentOrg!.orgBiz !== null) {
			await orgStore.currentOrg!.orgBiz.fetch();
		}
	}

	React.useEffect(() => {
		if (!fetched) {
			fetch().then(() => setFetched(true));
		}
	}, [orgStore, orgId]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["card", SectionItemId.Biz])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="activity" />
					<OrgHeader />
					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content org={orgStore.currentOrg!} />
					)}
				</div>
			</div>
		</>
	);
});
