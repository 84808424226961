import { action } from "mobx";

import { actions } from "@actions";
import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";

import { ScopeCode } from "../../../constants/external";
import { HistorySection } from "./history-section";
import { MainInfoSection } from "./main-info-section";
import { StaffSection } from "./staff-section";

export { MainInfoSection, HistorySection, StaffSection };

export class OrgInfoModel extends Section {
	public mainInfo: MainInfoSection;
	public history: HistorySection | null = null;
	public staff: StaffSection | null = null;

	public constructor(scope: XScopeChildInfo, private typeScope: XScopeChildInfo | null) {
		super(scope);

		this.mainInfo = new MainInfoSection(scope, typeScope);
	}

	public async fetch() {
		if (this.typeScope) {
			const children = await actions.getScopeChildren(this.typeScope.id);

			this.mapTypeScopes(children);
		}

		await super.fetch();
	}

	@action
	private mapTypeScopes(scopes: XScopeChildInfo[]) {
		for (const scope of scopes) {
			switch (scope.type.code) {
				case ScopeCode.History:
					this.history = new HistorySection(scope);
					break;
				case ScopeCode.Staff:
					this.staff = new StaffSection(scope);
					break;
			}
		}
	}
}
