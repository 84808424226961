import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { Overlay } from "@app-components/controls/overlay";
import { Path } from "@helpers/navigation";
import { ScopeFileItem } from "@app-components/files/scope-file-item";
import { ScopePanel } from "@app-components/scope-panel";

import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "@rossotr/components/org-navigation";
import { OrgHeader } from "@rossotr/components/org-header";
import { OrgModel } from "@rossotr/models/org-model";
import { StoreContext } from "../../context";

const Content = observer(({ org }: { org: OrgModel }) => {
	if (org.orgDocuments === null) {
		return null;
	}

	return (
		<>
			<ScopePanel section={org.orgDocuments}>
				<table className="org-reports">
					<tbody>
						{org.orgDocuments.files.map(x => (
							<tr key={x.id}>
								<td>{x.filename}</td>
								<td>
									<ScopeFileItem file={x} scopeId={org.id} caption="Скачать" />
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</ScopePanel>
		</>
	);
});

export const OrgDocuments = observer(function() {
	const [fetched, setFetched] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	React.useEffect(() => {
		if (!fetched) {
			orgStore.fetchOrg(orgId).then(() => setFetched(true));
		}
	}, [orgStore, orgId]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["documents"])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="documents" />
					<OrgHeader />
					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content org={orgStore.currentOrg!} />
					)}
				</div>
			</div>
		</>
	);
});
