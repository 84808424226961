import { observable } from "mobx";

import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";
import { AttributeEntry } from "@helpers/scope-attributes";

import { OrgScopeAttributes } from "../../../constants/external";

export class MainInfoSection extends Section {
	public contacts: AttributeEntry[];
	public status: AttributeEntry[];
	public manager: AttributeEntry[];
	public chiefAccountant: AttributeEntry[];
	public details: AttributeEntry[];
	public fullName: string;
	public shortName: string;

	@observable public type: string = "Неизвестный тип организации";

	public constructor(scope: XScopeChildInfo, typeScope: XScopeChildInfo | null) {
		super(scope, "Основные сведения");

		this.fullName = this.getAttribute([OrgScopeAttributes.FullName, "Полное наименование"])?.value ?? "";
		this.shortName = this.getAttribute([OrgScopeAttributes.ShortName, "Сокращенное наименование"])?.value ?? "";

		if (typeScope) {
			this.type = typeScope.name;
		}

		this.contacts = this.getAttributes([
			OrgScopeAttributes.Country,
			OrgScopeAttributes.AddressRu,
			OrgScopeAttributes.AddressForeign,
			OrgScopeAttributes.Phone,
			OrgScopeAttributes.Fax,
			OrgScopeAttributes.Email,
			OrgScopeAttributes.Website,
		]);

		this.status = this.getAttributes([OrgScopeAttributes.Status, OrgScopeAttributes.FoundationYear]);

		this.manager = this.getAttributes([
			[OrgScopeAttributes.ManagerFullName, "Ф.И.О."],
			[OrgScopeAttributes.ManagerPosition, "Должность"],
			[OrgScopeAttributes.ManagerAppointedBy, "Назначивший орган"],
			[OrgScopeAttributes.ManagerTermOfOffice, "Срок действия полномочий"],
			[OrgScopeAttributes.ManagerPhoneOffice, "Телефон рабочий"],
			[OrgScopeAttributes.ManagerPhoneMobile, "Телефон мобильный"],
			[OrgScopeAttributes.ManagerAppointmentOrderId, "Номер"],
			[OrgScopeAttributes.ManagerAppointmentOrderDate, "Дата"],
			[OrgScopeAttributes.ManagerContractType, "Тип"],
			[OrgScopeAttributes.ManagerContractId, "Номер"],
			[OrgScopeAttributes.ManagerContractDate, "Дата"],
		]);

		this.chiefAccountant = this.getAttributes([
			[OrgScopeAttributes.ChiefAccountantFullName, "Ф.И.О."],
			[OrgScopeAttributes.ChiefAccountantPosition, "Должность"],
			[OrgScopeAttributes.ChiefAccountantAppointedBy, "Назначивший орган"],
			[OrgScopeAttributes.ChiefAccountantTermOfOffice, "Срок действия полномочий"],
			[OrgScopeAttributes.ChiefAccountantPhoneOffice, "Телефон рабочий"],
			[OrgScopeAttributes.ChiefAccountantPhoneMobile, "Телефон мобильный"],
			[OrgScopeAttributes.ChiefAccountantAppointmentOrderId, "Номер"],
			[OrgScopeAttributes.ChiefAccountantAppointmentOrderDate, "Дата"],
			[OrgScopeAttributes.ChiefAccountantContractType, "Тип"],
			[OrgScopeAttributes.ChiefAccountantContractId, "Номер"],
			[OrgScopeAttributes.ChiefAccountantContractDate, "Дата"],
		]);

		this.details = this.getAttributes([
			OrgScopeAttributes.MortgageRCSC,
			OrgScopeAttributes.NumberDateRCSC,
			OrgScopeAttributes.EngineeringProtection,
			OrgScopeAttributes.Security,
			OrgScopeAttributes.BankingDetails,
		]);
	}
}
