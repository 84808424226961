import React from "react";
import { observer } from "mobx-react";

import { ScopePanel } from "@app-components/scope-panel";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { Row } from "@app-components/panel-test/row";
import { Column } from "@app-components/panel-test/column";
import { LabelValueRow } from "@app-components/panel";

import { MainInfoSection } from "../../models/org-model/org-info-model";

interface Props {
	section: MainInfoSection;
}

export const MainInfoPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<div className="org-panel-info__row">
				<div className="org-panel-info__column">
					<PanelSection title="Полное наименование">{section.fullName}</PanelSection>
					<PanelSection title="Сокращенное наименование">{section.shortName}</PanelSection>
					<PanelSection title="Тип организации">{section.type}</PanelSection>
				</div>
				<div className="org-panel-info__column">
					<PanelSection title="Контакты">
						{section.contacts.map(attr => (
							<LabelValueRow attr={attr} key={attr.key} />
						))}
					</PanelSection>
				</div>
			</div>
			<div>
				<PanelSection title="Статус">
					{section.status.map(attr => (
						<LabelValueRow attr={attr} key={attr.key} />
					))}
				</PanelSection>
				<Row topSeparated>
					<Column>
						<PanelSection title="Руководитель">
							{section.manager.map(({ label, value, key }) => (
								<div key={key} className="org-panel-data-row">
									<div className="org-panel-data-row__label">{label}</div> {value}
								</div>
							))}
						</PanelSection>
					</Column>
					<Column>
						<PanelSection title="Главный бухгалтер">
							{section.chiefAccountant.map(({ label, value, key }) => (
								<div key={key} className="org-panel-data-row">
									<div className="org-panel-data-row__label">{label}</div> {value}
								</div>
							))}
						</PanelSection>
					</Column>
				</Row>

				<PanelSection title="Характеристика">
					{section.details.map(({ label, value, key }) => (
						<div key={key} className="org-panel-data-row org-panel-data-row--2">
							<div className="org-panel-data-row__label">{label}</div> {value}
						</div>
					))}
				</PanelSection>
			</div>
		</ScopePanel>
	);
});
