import { XScopeCard, XScopeChildInfo } from "@external-types/scope";

import { OrgBizModel } from "@rossotr/models/org-model/org-biz-model";
import { OrgObjectsModel } from "@rossotr/models/org-model/org-objects-model";
import { OrgTasksStore } from "@rossotr/models/org-model/org-tasks";

import { OrgInfoModel } from "./org-info-model";
import { OrgPropertyModel } from "./org-property-model";
import { OrgEventsModel } from "./org-events-model";
import { OrgAgreementsModel } from "./org-agreements-model";
import { OrgDocumentsModel } from "./org-documents-model";
import { OrgReportsModel } from "./org-reports-model";

export class OrgModel {
	public objects: OrgObjectsModel = new OrgObjectsModel();
	public tasks: OrgTasksStore;

	public orgInfo: OrgInfoModel;
	public orgProperty: OrgPropertyModel | null = null;
	public orgEvents: OrgEventsModel | null = null;
	public orgBiz: OrgBizModel | null = null;
	public orgAgreements: OrgAgreementsModel | null = null;
	public orgDocuments: OrgDocumentsModel | null = null;
	public orgReports: OrgReportsModel | null = null;

	public constructor(public card: XScopeCard, public typeScope: XScopeChildInfo | null) {
		this.tasks = new OrgTasksStore(card.id);
		this.orgInfo = new OrgInfoModel(card, typeScope);
		this.orgReports = new OrgReportsModel(card);

		if (typeScope) {
			this.orgProperty = new OrgPropertyModel(typeScope);
			this.orgEvents = new OrgEventsModel(typeScope);
			this.orgBiz = new OrgBizModel(typeScope);
			this.orgAgreements = new OrgAgreementsModel(typeScope);
			this.orgDocuments = new OrgDocumentsModel(typeScope);
		}
	}

	public get logoUrl() {
		return "/assets/logo.png";
	}

	public get files() {
		return this.card.files;
	}

	public get id() {
		return this.card.id;
	}

	public get name() {
		return this.card.name;
	}
}
