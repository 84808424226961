import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { mapAttributes } from "@helpers/scope-attributes";
import { Section } from "@app-models/section/section";
import { TabbedControlData } from "@app-models/tabbed-control";

export class TabbedSection extends Section {
	@observable public data: TabbedControlData | null = null;

	public async fetch() {
		const children = await actions.getScopeChildren(this.id);

		if (children.length > 0) {
			runInAction(() => {
				this.data = {
					title: this.title,
					items: children.map(b => ({
						groupName: b.name,
						key: b.id,
						items: mapAttributes(b.attrs, b.type),
					})),
				};
			});
		}

		await super.fetch();
	}
}
