import React from "react";
import { SvgIcon } from "@app-types/models";
import cn from "classnames";

interface Props {
	name: string;
	Icon: React.ComponentType<SvgIcon>;
	expanded?: boolean;
	children?: React.ReactNode;
	onClick: () => void;
	active: boolean;
}

export function OrgNavigationSection({ Icon, name, expanded = false, active, onClick, children }: Props) {
	return (
		<div
			className={cn(
				"sidebar-section",
				{ "sidebar-section--expanded": expanded },
				{ "sidebar-section--active": active },
			)}
		>
			<div className="sidebar-section-title" onClick={onClick}>
				<div className="sidebar-section-title__icon">
					<Icon className="sidebar-section-title-icon" />
				</div>
				{name}
			</div>
			{expanded && children}
		</div>
	);
}
