import React from "react";
import { ArrowBackIcon } from "@icons";

interface Props {
	onClick: () => void;
}

export function BackButton({ onClick }: Props) {
	function handleClick(e: React.FormEvent) {
		e.stopPropagation();

		onClick();
	}

	return (
		<div onClick={handleClick} className="arrow-back-btn">
			<ArrowBackIcon className="arrow-back-btn__icon" />
		</div>
	);
}
