import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { PanelTabsSection1 } from "@app-components/panel-test/panel-tabs-section";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import { TransferredSection } from "../../models/org-model/org-object-model";

interface Props {
	section: TransferredSection;
}

export const TransferredPanel = observer(({ section }: Props) => {
	if (section.isEmpty) {
		return null;
	}

	return (
		<ScopePanel section={section}>
			<PanelSection>
				<div className="org-object-card__title">Передано сторонним организациям</div>
				{section.transferredToThirdParty.map(({ label, value, key }) => (
					<LabelValueRow label={label} value={value} key={key} labelClassName="label-30-perc" />
				))}
			</PanelSection>
			{section.leasedScope != null && (
				<PanelSection title={section.leasedScope.title}>
					<PanelTabsSection1 data={section.leasedScope} />
				</PanelSection>
			)}
			{section.transferredForFreeScope != null && (
				<PanelSection title={section.transferredForFreeScope.title}>
					<PanelTabsSection1 data={section.transferredForFreeScope} />
				</PanelSection>
			)}
			{section.transferredByOtherReasonsScope != null && (
				<PanelSection title={section.transferredByOtherReasonsScope.title}>
					<PanelTabsSection1 data={section.transferredByOtherReasonsScope} />
				</PanelSection>
			)}
		</ScopePanel>
	);
});
