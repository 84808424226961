export function formatOrgPath(route: string, orgId: number | string) {
	return route.replace(":id", String(orgId));
}

export class Path {
	public constructor(private levels: string[]) {}

	public getByLevel(level: number) {
		if (level > this.levels.length - 1) {
			return null;
		}

		return this.levels[level];
	}

	public includes(pathKey: string, level: number | null = null) {
		if (level === null) {
			return this.levels.includes(pathKey);
		}

		return this.getByLevel(level) === pathKey;
	}

	public setActive(pathKey: string, level: number) {
		if (level < this.levels.length - 1) {
			this.levels.splice(level, this.levels.length - 1 - level);
		}

		this.levels[level] = pathKey;

		return new Path(this.levels);
	}

	public isActive(pathKey: string) {
		return this.levels[this.levels.length - 1] === pathKey;
	}
}
