import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { Row } from "@app-components/panel-test/row";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import { DescriptionSection } from "../../models/org-model/org-object-model";

interface Props {
	section: DescriptionSection;
}

export const DescriptionPanel = observer(({ section }: Props) => {
	if (section.isEmpty) {
		return null;
	}

	return (
		<ScopePanel section={section}>
			<PanelSection>
				<Row bottomIndent={40}>
					<LabelValueRow attr={section.info} vertical />
				</Row>

				{section.features.map(({ label, value, key }) => (
					<LabelValueRow label={label} value={value} key={key} labelClassName="label-20-perc" />
				))}
			</PanelSection>
		</ScopePanel>
	);
});