import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 23 26" fill="currentColor" className={className}>
			<g opacity="0.802595">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M3.69362 0H21.204C21.713 0 22.1256 0.412614 22.1257 0.921564V23.04C22.1257 23.549 21.713 23.9616 21.204 23.9616H18.4392V24.8832C18.4392 25.3922 18.0266 25.8048 17.5176 25.8048H0.92882C0.419816 25.8048 0.00720215 25.3922 0.00720215 24.8832V4.60804C0.00720215 4.09903 0.419816 3.68642 0.92882 3.68642H2.772V0.921618C2.772 0.412614 3.18462 0 3.69362 0ZM1.85044 23.9616H16.596H16.5961V10.1376H12.9096C12.4006 10.1376 11.988 9.72502 11.988 9.21602V5.5296H1.85044V23.9616ZM13.8312 8.2944V6.83273L15.2929 8.2944H13.8312ZM18.4392 22.1184H20.2824V1.84318H4.61524V3.68642H12.9096C13.1533 3.68766 13.3869 3.78421 13.5603 3.9555L18.1683 8.56354C18.3407 8.73709 18.438 8.9714 18.4392 9.21602V22.1184Z"
				/>
				<path d="M13.8312 11.981H4.61522C4.10622 11.981 3.6936 12.3936 3.6936 12.9026C3.6936 13.4116 4.10622 13.8241 4.61522 13.8241H13.8312C14.3402 13.8241 14.7529 13.4115 14.7529 12.9025C14.7529 12.3935 14.3402 11.981 13.8312 11.981Z" />
				<path d="M13.8312 15.667H4.61522C4.10622 15.667 3.6936 16.0796 3.6936 16.5886C3.6936 17.0976 4.10622 17.5102 4.61522 17.5102H13.8312C14.3402 17.5102 14.7529 17.0976 14.7529 16.5886C14.7529 16.0796 14.3402 15.667 13.8312 15.667Z" />
				<path d="M13.8312 19.3535H4.61522C4.10622 19.3535 3.6936 19.7661 3.6936 20.2751C3.6936 20.7841 4.10622 21.1968 4.61522 21.1968H13.8312C14.3402 21.1968 14.7529 20.7841 14.7529 20.2751C14.7528 19.7661 14.3402 19.3535 13.8312 19.3535Z" />
			</g>
		</svg>
	);
}
