import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { AttributeEntry } from "@helpers/scope-attributes";
import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";

export class MainSection extends Section {
	public details: AttributeEntry[] = [];
	public properties: AttributeEntry[] = [];

	@observable public photoScope: XScopeChildInfo | null = null;

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Основное");

		this.details = this.getAttributes([
			"Основания использования",
			"Основания и срок использования",
			"Инвентарный номер",
		]);

		this.properties = this.getAttributes([
			["Наименование объекта Россотрудничества (посольство, генконсульство и т. д.)", "Наименование объекта"],
			"Адрес (на русском языке)",
			"Адрес (на языке страны пребывания)",
			"Тип здания",
			["Назначение здания (представительское, служебное, жилое и т. д.)", "Назначение здания"],
			"Год постройки",

			"Адрес земельного участка (на русском языке)",
			"Адрес земельного участка (на языке страны пребывания)",
			"Назначение земельного участка",

			"Марка, модель автомобиля",
			"Год выпуска",
			"Мощность двигателя",
			"Пробег в км.",

			"Год приобретения",
			"Дата приемки в эксплуатацию",
			"Заключение о состоянии здания",
		]);
	}

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.scope.id);

		runInAction(() => {
			this.photoScope =
				scopes.find(scope => ["Фотографии объекта", "Фотографии"].includes(scope.name.trim())) ?? null;
		});

		await super.fetch();
	}
}
