import React from "react";
import { useObserver } from "mobx-react-lite";
import debounce from "lodash/debounce";
import { Overlay } from "@app-components/controls/overlay";
import { InlineLoader } from "@app-components/controls/inline-loader";
import { Pagination } from "@app-components/pagination";
import { IDataSource } from "@app-types/data";

const CLIENT_HEIGHT_BUFFER = 100;

interface Props<TItem> {
	store: IDataSource<TItem>;
	EntryComponent: React.ComponentClass<{ entry: TItem }> | React.FunctionComponent<{ entry: TItem }>;
	Header?: React.ReactNode;
	createKey: (entry: TItem) => string | number;
	captions?: string[];
	pagerPosition?: "top" | "bottom" | "both" | "none";
	useInfiniteFetch?: boolean;
}

export const InfiniteDataRepeater = function<TItem>({
	store,
	EntryComponent,
	createKey,
	pagerPosition = "bottom",
	useInfiniteFetch = true,
}: Props<TItem>) {
	const refetchOrSetActivePage = React.useCallback(
		debounce(() => {
			const scrollingElement = document.scrollingElement;
			if (scrollingElement) {
				const pixelsUntilDone = scrollingElement.scrollHeight - scrollingElement.scrollTop;
				if (store.hasMore) {
					const viewPortAndBuffer = scrollingElement.clientHeight + CLIENT_HEIGHT_BUFFER;
					const shouldRefetch = pixelsUntilDone < viewPortAndBuffer;

					if (shouldRefetch) {
						store.fetchNextPage();
					}
				}
			}
		}, 1000),
		[store],
	);

	React.useEffect(() => {
		if (useInfiniteFetch) {
			window.addEventListener("scroll", refetchOrSetActivePage);

			return () => {
				window.removeEventListener("scroll", refetchOrSetActivePage);
			};
		}
	}, [refetchOrSetActivePage]);

	const updatePage = React.useCallback(
		async (x, y) => {
			document.scrollingElement!.scrollTo(0, 0);
			await store.fetchPage(x, y);
		},
		[store],
	);

	return useObserver(() => (
		<>
			{store.loading && <Overlay />}
			{store.items.length > 0 && (
				<>
					{["top", "both"].includes(pagerPosition) && (
						<Pagination
							pagination={store.pagination}
							onChange={updatePage}
							className="data-grid-pagination--top"
						/>
					)}
					{store.items.map((item, index) => (
						<EntryComponent key={`${createKey(item)}_${index}`} entry={item} />
					))}

					{store.loadingNext && <InlineLoader text="Загрузка следующей страницы" />}
					{store.items.length > 0 && ["bottom", "both"].includes(pagerPosition) && (
						<Pagination pagination={store.pagination} onChange={updatePage} />
					)}
				</>
			)}
		</>
	));
};
