import React, { useContext, useEffect } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";

import { SearchIcon } from "@icons";
import { Textbox } from "@app-ui";
import { AppHeader } from "@rossotr/components/app-header";
import { Layout, LayoutSwitch } from "@app-components/controls/layout-switch";
import { Overlay } from "@app-components/controls/overlay";
import { InfiniteDataRepeater } from "@app-components/infinite-data-repeater";
import { useDebouncedEffect } from "@shared/effects/use-debounce-effect";

import { StoreContext } from "../../context";
import { OrgGridItem } from "./org-grid-item";
import { OrgListItem } from "./org-list-item";

export const Orgs = observer(function() {
	const { orgsStore } = useContext(StoreContext);
	const [layout, setLayout] = React.useState(Layout.Grid);
	const [searchTerm, setSearchTerm] = React.useState<string>("");

	useEffect(() => {
		orgsStore.fetchPage();
	}, []);

	useDebouncedEffect(
		() => {
			orgsStore.applyFilter("search", searchTerm);
		},
		1000,
		[searchTerm],
	);

	function toggleLayout() {
		setLayout(layout === Layout.Grid ? Layout.List : Layout.Grid);
	}

	function handleSearchTerm(value: string) {
		setSearchTerm(value);
	}

	const CardComponent = layout === Layout.Grid ? OrgGridItem : OrgListItem;
	const isEmpty = !orgsStore.loading && orgsStore.items.length === 0;

	if (orgsStore.loading) {
		return (
			<>
				<AppHeader />
				<div className="app__content">
					<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
						<div className="content-header">
							<div className="content-header__title">Представительства Россотрудничества</div>
							<div className="content-header__toolbar page-toolbar">
								<div className="page-toolbar__search">
									<Textbox
										placeholder="Поиск..."
										Icon={<SearchIcon className="search-icon" />}
										onChange={handleSearchTerm}
										value={searchTerm}
									/>
								</div>
								<LayoutSwitch onToggle={toggleLayout} layout={layout} />
							</div>
						</div>
						<Overlay global={false} globalWait />
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<AppHeader />
			<div className="app__content">
				<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
					<div className="content-header">
						<div className="content-header__title">Представительства Россотрудничества</div>
						<div className="content-header__toolbar page-toolbar">
							<div className="page-toolbar__search">
								<Textbox
									placeholder="Поиск..."
									Icon={<SearchIcon className="search-icon" />}
									onChange={handleSearchTerm}
									value={searchTerm}
								/>
							</div>
							<LayoutSwitch onToggle={toggleLayout} layout={layout} />
						</div>
					</div>
					{isEmpty ? (
						<div>Нет данных, удовлетворяющих поиску.</div>
					) : (
						<div className={cn("orgs-content", `orgs-content--${layout}`)}>
							<InfiniteDataRepeater
								store={orgsStore}
								EntryComponent={({ entry }) => <CardComponent item={entry} />}
								createKey={x => x.id}
								pagerPosition="none"
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
});
