import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";

import { Overlay } from "@app-components/controls/overlay";
import { Path } from "@helpers/navigation";

import { AppHeader } from "../../components/app-header";
import { OrgBreadcrumb, OrgNavigation, SectionItemId } from "../../components/org-navigation";
import { OrgModel } from "../../models/org-model";
import { OrgHeader } from "../../components/org-header";

import { StoreContext } from "../../context";
import { MainInfoPanel } from "./main-info-panel";
import { StaffPanel } from "./staff-panel";
import { HistoryPanel } from "./history-panel";

interface Props {
	currentOrg: OrgModel;
}

const Content = observer(({ currentOrg }: Props) => {
	const orgInfo = currentOrg.orgInfo;

	return (
		<>
			<MainInfoPanel section={orgInfo.mainInfo} />
			{orgInfo.staff && <StaffPanel section={orgInfo.staff} />}
			{orgInfo.history && <HistoryPanel section={orgInfo.history} />}
		</>
	);
});

export const OrgInfo = observer(function() {
	const { id } = useParams<{ id: string }>();
	const [fetched, setFetched] = React.useState(false);
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	React.useEffect(() => {
		if (!fetched) {
			fetch().then(() => setFetched(true));
		}
	}, [orgStore, orgId]);

	async function fetch() {
		await orgStore.fetchOrg(orgId);
		await orgStore.currentOrg!.orgInfo.fetch();
	}

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["card", SectionItemId.Basic])} orgId={orgId} />

				<div className="sidebar-content">
					<OrgBreadcrumb id="org" />
					<OrgHeader />

					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content currentOrg={orgStore.currentOrg!} />
					)}
				</div>
			</div>
		</>
	);
});
