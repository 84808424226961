import { Paths } from "../../routes/paths";
import { generatePath } from "react-router";
import React, { useContext } from "react";
import { StoreContext } from "../../context";
import { OrgModel } from "@rossotr/models/org-model";

interface BreadcrumbItem {
	url: (org: OrgModel) => string;
	name: (org: OrgModel) => string;
	id: string;
	parent: null | string;
}

const links: BreadcrumbItem[] = [
	{
		url: () => "/",
		name: () => "Главная",
		id: "home",
		parent: null,
	},
	{
		url: () => Paths.Orgs,
		name: () => "Представительства Россотрудничества",
		id: "orgs",
		parent: "home",
	},
	{
		url: (org: OrgModel) => generatePath(Paths.OrgInfo, { id: org.id }),
		name: (org: OrgModel) => org.name,
		id: "org",
		parent: "orgs",
	},
	{
		url: (org: OrgModel) => generatePath(Paths.OrgAgreements, { id: org.id }),
		name: () => "Соглашения",
		id: "agreements",
		parent: "org",
	},
	{
		id: "property",
		name: () => "Имущественный комплекс",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgProperty, { id: org.id }),
	},
	{
		id: "events",
		name: () => "Мероприятия",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgEvents, { id: org.id }),
	},
	{
		id: "activity",
		name: () => "Деятельность",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgBiz, { id: org.id }),
	},
	{
		id: "documents",
		name: () => "Документы",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgDocuments, { id: org.id }),
	},
	{
		id: "tasks",
		name: () => "Заявки",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgTasks, { id: org.id }),
	},
	{
		id: "objects",
		name: () => "Объекты",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgObjects, { id: org.id }),
	},
	{
		id: "reports",
		name: () => "Отчеты",
		parent: "org",
		url: (org: OrgModel) => generatePath(Paths.OrgReports, { id: org.id }),
	},
];

interface Props {
	id: string;
}

export function OrgBreadcrumb({ id }: Props) {
	const { orgStore } = useContext(StoreContext);
	const currentOrg = orgStore.currentOrg;
	const [items, setItems] = React.useState<BreadcrumbItem[]>([]);

	React.useEffect(() => {
		if (currentOrg != null) {
			let res = links.find(link => link.id === id);
			const result: BreadcrumbItem[] = [];
			if (res) {
				result.push(res);
			}

			while (res && res.parent) {
				res = links.find(link => link.id === res!.parent);
				if (res) {
					result.unshift(res);
				}
			}

			setItems(result);
		}
	}, [currentOrg]);

	if (!currentOrg) {
		return null;
	}

	return (
		<div className="sidebar-content__breadcrumb">
			{items.map((item, index) => (
				<React.Fragment key={item.id}>
					{index === items.length - 1 ? (
						<>
							<span className="active">{item.name(orgStore.currentOrg!)}</span> /
						</>
					) : (
						<>
							<a href={item.url(orgStore.currentOrg!)}>{item.name(orgStore.currentOrg!)}</a> /{" "}
						</>
					)}
				</React.Fragment>
			))}
		</div>
	);
}
