import React from "react";
import { observer } from "mobx-react";
import cn from "classnames";
import { breakByLine } from "@helpers/text";
import { TabbedControl, TabbedControlData, TabGroup, TabItem } from "@app-models/tabbed-control";

interface Props {
	control: TabbedControl;
}

function TabGroupItem({
	tab,
	onClick,
	activeKey,
}: {
	tab: TabGroup;
	activeKey: string | number;
	onClick: (key: string | number) => void;
}) {
	if (!tab.groupName) {
		return null;
	}

	function handleClick() {
		if (!tab.disabled) {
			onClick(tab.key);
		}
	}

	const subTabs = (tab.items as TabGroup[]).filter(sub => sub.groupName);

	return (
		<>
			<div
				key={tab.key}
				onClick={handleClick}
				className={cn("org-panel-tab", {
					"org-panel-tab--active": tab.key === activeKey,
					"org-panel-tab--disabled": tab.disabled,
				})}
			>
				{tab.groupName}
			</div>

			{subTabs.length > 0 && (
				<div className="org-panel-sub-tabs">
					{subTabs.map(tab => (
						<TabGroupItem key={tab.key} tab={tab} onClick={onClick} activeKey={activeKey} />
					))}
				</div>
			)}
		</>
	);
}

function TabContentItem({ item }: { item: TabItem }) {
	const lines = breakByLine(String(item.value));

	return (
		<>
			<div className="org-panel-data-row">
				<div className="org-panel-data-row__label">{item.label}</div>
				<div>{lines.length > 1 ? lines.map((l, index) => <p key={index}>{l}</p>) : item.value}</div>
			</div>
			{item.items != null && (
				<div>
					{item.items.map(i => (
						<TabContentItem key={i.key} item={i} />
					))}
				</div>
			)}
		</>
	);
}

export const PanelTabsSection = observer(({ control }: Props) => {
	function handleClick(key: number | string) {
		control.updateKey(key);
	}

	const activeTab = control.activeTab;

	return (
		<div className="org-panel-info__row">
			<div className="org-panel-info__column">
				<div className="org-panel-info__section-content">
					{control.data.items.map(tab => (
						<TabGroupItem activeKey={control.activeTabKey} onClick={handleClick} tab={tab} key={tab.key} />
					))}
				</div>
			</div>
			<div className="org-panel-info__column">
				<div className="org-panel-info__section-content">
					{activeTab && (activeTab.items as TabItem[]).map(x => <TabContentItem key={x.key} item={x} />)}
				</div>
			</div>
		</div>
	);
});

interface Props1 {
	data: TabbedControlData;
}

export const PanelTabsSection1 = observer(({ data }: Props1) => {
	const [controlRef, setControl] = React.useState<TabbedControl>();

	React.useEffect(() => {
		setControl(new TabbedControl(data));
	}, [data]);

	function handleClick(key: number | string) {
		controlRef!.updateKey(key);
	}

	const control = controlRef;

	if (!control) {
		return null;
	}

	const activeTab = control.activeTab;

	return (
		<div className="org-panel-info__row">
			<div className="org-panel-info__column">
				<div className="org-panel-info__section-content">
					{control.data.items.map(tab => (
						<TabGroupItem activeKey={control.activeTabKey} onClick={handleClick} tab={tab} key={tab.key} />
					))}
				</div>
			</div>
			<div className="org-panel-info__column">
				<div className="org-panel-info__section-content">
					{activeTab && (activeTab.items as TabItem[]).map(x => <TabContentItem key={x.key} item={x} />)}
				</div>
			</div>
		</div>
	);
});
