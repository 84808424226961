import React from "react";
import { observer } from "mobx-react";

import { ScopePanel } from "@app-components/scope-panel";
import { Row } from "@app-components/panel-test/row";
import { Column } from "@app-components/panel-test/column";
import { PanelSection } from "@app-components/panel-test/panel-section";

import { StaffSection } from "../../models/org-model/org-info-model";

interface Props {
	section: StaffSection;
}

export const StaffPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<Row>
				<Column borderless>
					<PanelSection>
						{section.items.map(({ key, value, label }) => (
							<div key={key} className="org-panel-data-row-1">
								<div className="org-panel-data-row-1__label">{label}</div>
								<div className="org-panel-data-row-1__value">{value}</div>
							</div>
						))}
					</PanelSection>
				</Column>
				<Column />
			</Row>
		</ScopePanel>
	)
})