import { DataSourceSession, Filters } from "@app-models/data-source";
import { IPagination } from "@app-types/data";
import { Pagination } from "@app-models/pagination";
import { SimpleOrgModel } from "../../models/simple-org-model";

export class OrgsSession extends DataSourceSession<SimpleOrgModel> {
	public pagination: IPagination;
	public filter: Filters = new Filters(true);

	public constructor() {
		super();

		const pagination = new Pagination();
		pagination.pageSize = 20;

		this.pagination = pagination;
	}
}
