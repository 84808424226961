import { actions } from "@actions";
import { action, observable, runInAction } from "mobx";
import { XProcessRef, XProcessTaskInfo, XTaskList } from "@external-types/process";
import { InfiniteDataSource, DataSourceSession, Filters } from "@app-models/data-source";

class TasksSession extends DataSourceSession<XProcessRef> {
	@observable public currentTask: XProcessTaskInfo | null = null;

	public filter: Filters = new Filters(true);
}

export class OrgTasksStore extends InfiniteDataSource<XProcessRef> {
	private _session = new TasksSession();

	public constructor(private scopeId: number) {
		super();
	}

	public async fetchTask(taskId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentTask = null;
		});

		const task = await actions.getTask(taskId);

		runInAction(() => {
			this.session.loading = false;
			this.session.currentTask = task;
		});
	}

	public get currentTask() {
		return this.session.currentTask;
	}

	protected get session() {
		return this._session;
	}

	protected fetch = async () => {
		const result = await actions.getScopeTasks(this.scopeId, this.pagination, this.filter);

		return this.updatePageData(result);
	};

	@action
	private updatePageData = async (descendants: XTaskList) => {
		this.updatePageInfo(descendants);

		return descendants.list;
	};
}
