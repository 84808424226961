import { AttributeEntry } from "@helpers/scope-attributes";
import React from "react";
import cn from "classnames";
import { observer } from "mobx-react";
import { ScopeFileList } from "@app-components/files/scope-file-list";
import { SectionContentWithPager } from "@app-models/section-content-with-pager";

interface PagerProps {
	count: number;
	active: number;
	onPageSelect: (pageId: number) => void;
}

function Pager({ count, active, onPageSelect }: PagerProps) {
	function handleClick(pageId: number) {
		return function() {
			onPageSelect(pageId);
		};
	}

	return (
		<div className="section-pager">
			<span className="section-pager__content">
				{[...Array(count).keys()].map(x => (
					<span
						key={x}
						className={cn("section-pager__item", { "section-pager__item--active": active === x })}
						onClick={handleClick(x)}
					>
						{x + 1}
					</span>
				))}
			</span>
		</div>
	);
}

export const ContentWithPager = observer(({ section }: { section: SectionContentWithPager }) => {
	function handlePageSelect(section: SectionContentWithPager) {
		return function(pageId: number) {
			section.setActivePage(pageId);
		};
	}

	if (section.activePage == null) {
		return <span>Нет данных</span>;
	}

	return (
		<>
			<Pager count={section.pages.length} active={section.pageNumber} onPageSelect={handlePageSelect(section)} />
			{section.activePage.attrs.map((item: AttributeEntry) => (
				<React.Fragment key={item.key}>
					<div className="org-panel-data-row">
						<div className="org-panel-data-row__label">{item.label}</div>
						<div>{item.value}</div>
					</div>
				</React.Fragment>
			))}

			<ScopeFileList files={section.activePage.files} scopeId={section.activePage!.scopeId} />
		</>
	);
});
