import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import { EconomicIndicatorsSection } from "../../models/org-model/org-object-model";

interface Props {
	section: EconomicIndicatorsSection;
}

export const AttributesPanel = observer(({ section }: Props) => {
	if (section.isEmpty) {
		return null;
	}

	return (
		<ScopePanel section={section}>
			<PanelSection>
				{section.items.map(({ label, value, key }) => (
					<LabelValueRow label={label} value={value} key={key} labelClassName="label-20-perc" />
				))}
			</PanelSection>
		</ScopePanel>
	);
});