import React from "react";
import { observer } from "mobx-react";

import { PanelTabsContent } from "@app-components/panel";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import {
	PlannedRenovationSection,
	ReviewResultsSection,
	FunctionalRoomSection,
} from "../../models/org-model/org-object-model";

interface Props {
	section: ReviewResultsSection | PlannedRenovationSection | FunctionalRoomSection;
}

export const ReviewResultsPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			{section.content != null && (
				<PanelTabsContent data={section.content} onTabChange={section.fetchScopeContent}>
					{section.activeContent.map(x => (
						<LabelValueRow label={x.label} value={x.value} key={x.key} />
					))}
				</PanelTabsContent>
			)}
		</ScopePanel>
	);
});
