import { action, observable, runInAction } from "mobx";

import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { Section, TabbedSection } from "@app-models/section";

import { BizProductionSection } from "./biz-production-section";
import { BizEducationSection } from "./biz-education-section";
import { BizResultsSection } from "./biz-results-section";
import { BizOtherInfoSection } from "./biz-other-info-section";
import { ScopeCode } from "../../../constants/external";

export { BizProductionSection, BizEducationSection, BizResultsSection, BizOtherInfoSection };

export class OrgBizModel extends Section {
	@observable public bizByCharterSection: TabbedSection | null = null;
	@observable public bizOtherInfoSection: BizOtherInfoSection | null = null;
	@observable public bizProductionSection: BizProductionSection | null = null;
	@observable public bizEducationSection: BizEducationSection | null = null;
	@observable public bizResultsSection: BizResultsSection | null = null;
	@observable public bizIndicatorsSection: Section | null = null;

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);
		const targetScope = scopes.find(scope => scope.type.code === ScopeCode.Biz);

		if (targetScope) {
			const children = await actions.getScopeChildren(targetScope.id);
			await this.mapScopes(children);
		}

		await super.fetch();
	}

	private async mapScopes(scopes: XScopeChildInfo[]) {
		for (const scope of scopes) {
			switch (scope.type.code) {
				case ScopeCode.BizByCharter:
					runInAction(() => {
						this.bizByCharterSection = new TabbedSection(scope);
					});
					break;
				case ScopeCode.BizOtherInfo:
					runInAction(() => {
						this.bizOtherInfoSection = new BizOtherInfoSection(scope);
					});
					break;
				case ScopeCode.BizProduction:
					runInAction(() => {
						this.bizProductionSection = new BizProductionSection(scope);
					});
					break;
				case ScopeCode.BizEducation:
					runInAction(() => {
						this.bizEducationSection = new BizEducationSection(scope);
					});
					break;
				case ScopeCode.BizResultsScope:
					await this.fetchBizResults(scope);
					break;
			}
		}
	}

	private async fetchBizResults(scope: XScopeChildInfo) {
		const scopes = await actions.getScopeChildren(scope.id);

		this.mapBizResults(scopes);
	}

	@action
	private mapBizResults(scopes: XScopeChildInfo[]) {
		for (const scope of scopes) {
			switch (scope.type.code) {
				case ScopeCode.BizResults:
					this.bizResultsSection = new BizResultsSection(scope);
					break;
				case ScopeCode.BizEconomicIndicators:
					this.bizIndicatorsSection = new Section(scope);
					break;
			}
		}
	}
}
