import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { Overlay } from "@app-components/controls/overlay";
import { Path } from "@helpers/navigation";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { PanelTabsSection1 } from "@app-components/panel-test/panel-tabs-section";

import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation, SectionItemId } from "@rossotr/components/org-navigation";
import { OrgModel } from "@rossotr/models/org-model";
import { OrgHeader } from "@rossotr/components/org-header";
import { StoreContext } from "../../context";

interface Props {
	org: OrgModel;
}

const Content = observer(({ org }: Props) => {
	if (org.orgProperty === null) {
		return null;
	}

	return (
		<>
			{org.orgProperty.children.map(child => {
				const attrs = child.getAttributes();
				const hasTabbedData = child.data !== null;

				return (
					<ScopePanel key={child.id} section={child}>
						{attrs.length > 0 && (
							<PanelSection useBottomBorder={hasTabbedData}>
								{attrs.map(attr => (
									<LabelValueRow attr={attr} key={attr.key} />
								))}
							</PanelSection>
						)}
						<PanelSection padding={false}>
							{hasTabbedData && <PanelTabsSection1 data={child.data!} />}
						</PanelSection>
					</ScopePanel>
				);
			})}
		</>
	);
});

export const OrgProperty = observer(function() {
	const [fetched, setFetched] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	async function fetch() {
		await orgStore.fetchOrg(orgId);

		if (orgStore.currentOrg!.orgProperty !== null) {
			await orgStore.currentOrg!.orgProperty.fetch();
		}
	}

	React.useEffect(() => {
		if (!fetched) {
			fetch().then(() => setFetched(true));
		}
	}, [orgStore, orgId]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["card", SectionItemId.Property])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="property" />
					<OrgHeader />
					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content org={orgStore.currentOrg!} />
					)}
				</div>
			</div>
		</>
	);
});
