import React from "react";
import { observer } from "mobx-react-lite";
import { ITask } from "./task-action/task";
import { LightButton } from "@app-components/buttons/light-button";

export const TaskActions = observer(function({ item }: { item: ITask }) {
	function handleChange(actionId: number) {
		return function() {
			item.updateActionId(actionId);

			if (item.submitAllowed) {
				item.runAction();
			}
		};
	}

	return (
		<div className="task-form-actions">
			<div className="task-form-actions__intermediate" />
			<div className="task-form-actions__finish">
				{item.actions.map(actionItem => (
					<span style={{ marginLeft: 20 }} key={actionItem.actionID}>
						<LightButton
							key={actionItem.actionID}
							onClick={handleChange(actionItem.actionID)}
							disabled={!item.submitAllowed}
						>
							{actionItem.action}
						</LightButton>
					</span>
				))}
			</div>
		</div>
	);
});
