import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopePanel } from "@app-components/scope-panel";

import { RealEstateSection } from "../../models/org-model/org-object-model";

interface Props {
	section: RealEstateSection;
}

export const RealEstatePanel = observer(({ section }: Props) => {
	if (section.isEmpty) {
		return null;
	}

	return (
		<ScopePanel section={section}>
			<PanelSection>
				{section.items.map((str, index) => (
					<p key={index}>{str}</p>
				))}
			</PanelSection>
		</ScopePanel>
	);
});