import { AppState } from "../app-state";
import { actions } from "@actions";
import { OrgModel } from "@rossotr/models/org-model";
import { computed, runInAction } from "mobx";
import { ScopeCode } from "@rossotr/constants/external";

export class OrgStore {
	public constructor(private appState: AppState) {}

	protected get session() {
		return this.appState.org;
	}

	private async getOrg(orgId: number, forceFetch: boolean = false) {
		if (!forceFetch && this.currentOrg != null && this.currentOrg.id === orgId) {
			return this.currentOrg;
		}

		const orgCard = await actions.getScope(orgId);
		const orgCardChildren = await actions.getScopeChildren(orgCard.id);
		const orgTypeDefRef = orgCardChildren.find(ch => ch.type.code === ScopeCode.OrgTypeCode);

		let orgTypeScope = null;

		if (orgTypeDefRef) {
			const orgTypeDef = await actions.getScopeChildren(orgTypeDefRef.id);
			orgTypeScope = orgTypeDef[0];
		}

		const orgModel = new OrgModel(orgCard, orgTypeScope);

		if (orgTypeScope) {
			const definitionScopes = await actions.getScopeChildren(orgTypeScope.id);
			const orgObjectsScopeRef = definitionScopes.find(ch => ch.type.code === ScopeCode.Objects);

			if (orgObjectsScopeRef) {
				await orgModel.objects.init(orgObjectsScopeRef);
			}
		}

		runInAction(() => {
			this.session.currentOrg = orgModel;
		});

		return orgModel;
	}

	public async fetchOrg(orgId: number, forceFetch: boolean = false) {
		runInAction(() => {
			this.session.loading = true;
		});

		await this.getOrg(orgId, forceFetch);

		runInAction(() => {
			this.session.loading = false;
		});
	}

	public async fetchObjects(orgId: number, categoryScopeId: number, subCategoryScopeId: number) {
		runInAction(() => {
			this.session.loading = true;
		});

		const orgModel = await this.getOrg(orgId);

		await orgModel.objects.fetch(categoryScopeId, subCategoryScopeId);

		runInAction(() => {
			this.session.loading = false;
		});
	}

	public async fetchObject(orgId: number, categoryScopeId: number, subCategoryScopeId: number, objectId: number) {
		runInAction(() => {
			this.session.loading = true;
		});

		const orgModel = await this.getOrg(orgId);

		await orgModel.objects.fetch(categoryScopeId, subCategoryScopeId);
		await orgModel.objects.fetchObject(objectId);

		runInAction(() => {
			this.session.loading = false;
		});
	}

	public async fetchOrgTasks(orgId: number) {
		runInAction(() => {
			this.session.loading = true;
		});

		const orgModel = await this.getOrg(orgId);
		await orgModel.tasks.fetchPage();

		runInAction(() => {
			this.session.loading = false;
		});
	}

	public async fetchOrgTask(orgId: number, taskId: number) {
		runInAction(() => {
			this.session.loading = true;
		});

		const orgModel = await this.getOrg(orgId);
		await orgModel.tasks.fetchTask(taskId);

		runInAction(() => {
			this.session.loading = false;
		});
	}

	@computed
	public get loading() {
		return this.session.loading;
	}

	@computed
	public get currentOrg() {
		return this.session.currentOrg;
	}
}
