import { observer } from "mobx-react-lite";
import React, { useContext } from "react";
import { useParams, RouteComponentProps, withRouter, generatePath } from "react-router";

import { Path } from "@helpers/navigation";
import { Overlay } from "@app-components/controls/overlay";
import { BackButton } from "@app-components/buttons/back-button";

import { StoreContext } from "../../context";
import { AppHeader } from "../../components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "../../components/org-navigation";
import { OrgHeader } from "../../components/org-header";
import { OrgObjectModel } from "../../models/org-model/org-object-model";
import { Paths } from "../../routes/paths";

import { MainPanel } from "./main-panel";
import { DescriptionPanel } from "./description-panel";
import { SpecificationPanel } from "./specification-panel";
import { ReviewResultsPanel } from "./review-results-panel";
import { RoomInfoPanel } from "./room-info-panel";
import { RealEstatePanel } from "./real-estate-panel";
import { AttributesPanel } from "./attributes-panel";
import { TransferredPanel } from "./transferred-panel";

const Content = observer(({ item, onBack }: { item: OrgObjectModel; onBack: () => void }) => {
	return (
		<>
			<div className="sidebar-content__sub-header">
				<BackButton onClick={onBack} /> {item.title}
			</div>
			<MainPanel section={item.mainSection} />
			<DescriptionPanel section={item.descriptionSection} />
			<SpecificationPanel section={item.specificationSection} />
			<AttributesPanel section={item.economicIndicatorsSection} />

			{item.reviewResultsSection != null && <ReviewResultsPanel section={item.reviewResultsSection} />}
			{item.roomInfoSection != null && <RoomInfoPanel section={item.roomInfoSection} />}
			{item.financeUsageSection != null && <RoomInfoPanel section={item.financeUsageSection} />}
			{item.plannedRenovationSection != null && <ReviewResultsPanel section={item.plannedRenovationSection} />}
			{item.functionalRoomSection != null && <ReviewResultsPanel section={item.functionalRoomSection} />}
			<TransferredPanel section={item.transferredSection} />
			<RealEstatePanel section={item.realEstateSection} />
		</>
	);
});

export const OrgObjectItem = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const [fetched, setFetched] = React.useState(false);
		const { id, category1, category2, objectId } = useParams<{
			id: string;
			category1: string;
			category2: string;
			objectId: string;
		}>();
		const { orgStore } = useContext(StoreContext);
		const orgId = parseInt(id, 10);

		React.useEffect(() => {
			setFetched(false);

			orgStore
				.fetchObject(orgId, parseInt(category1, 10), parseInt(category2, 10), parseInt(objectId, 10))
				.then(() => {
					setFetched(true);
				});
		}, [orgStore, orgId, category1, category2]);

		function handleBack() {
			history.push(generatePath(Paths.OrgObjectsCategory, { id, category1, category2 }));
		}

		return (
			<>
				<AppHeader />
				<div className="app__content app-content app-content--sidebar">
					<OrgNavigation path={new Path(["objects", category1, category2])} orgId={orgId} />
					<div className="sidebar-content">
						<OrgBreadcrumb id="objects" />
						<OrgHeader />
						{orgStore.loading || !fetched ? (
							<Overlay global={false} globalWait />
						) : (
							<Content item={orgStore.currentOrg!.objects.currentObject!} onBack={handleBack} />
						)}
					</div>
				</div>
			</>
		);
	}),
);
