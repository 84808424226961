import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";
import { breakByLine } from "@helpers/text";
import { OrgScopeAttributes } from "../../../constants/external";

export class HistorySection extends Section {
	public value: string[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope);

		const attr = this.getAttribute([OrgScopeAttributes.History]);

		if (attr) {
			this.value = breakByLine(attr.value);
		}
	}
}
