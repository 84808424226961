import React, { useContext } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { AppHeader } from "@rossotr/components/app-header";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { StoreContext } from "../../context";
import { Overlay } from "@app-components/controls/overlay";
import {
	XHistoryItem,
	XProcessAdminInfo,
	XProcessRef,
	XProcessTaskInfo,
	XTaskInfo,
	XTaskValueInfo,
} from "@external-types/process";
import { DateTimeFormats, formatDateTime, isExpired } from "@helpers/datetime";
import { fullName } from "@helpers/user";
import { XShemaFormItemType } from "@external-types/schema";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { LabelValueRow } from "@app-components/panel";
import { Panel } from "@app-components/panel";
import { ITask, Task as TaskModel } from "./task-action/task";
import { TaskItemAction } from "./task-action/task-item-action";
import { TaskActions } from "./task-actions";
import { XNextTaskRef } from "@external-types/process/next-task-ref";
import { Paths } from "../../routes/paths";
import { TaskFileItem } from "@app-components/files/task-file-item";
import { Column } from "@app-components/panel-test/column";
import { Row } from "@app-components/panel-test/row";
import { BackButton } from "@app-components/buttons/back-button";
import { PanelTabsSection2 } from "../../../../../src/components/panel-test/panel-tabs-section2";
import { TabbedControlData } from "../../../../../src/models/tabbed-control";

function TaskFieldValue({ field, taskId }: { field: XTaskValueInfo; taskId: number }) {
	if (field.type === XShemaFormItemType.Media) {
		return (
			<div style={{ marginTop: 40 }}>
				<div className={cn("org-panel-data-row")}>
					<div className={cn("org-panel-data-row__label org-panel-data-row__label--auto")}>{field.name}</div>
				</div>

				<div className="task-file-list">
					{field.files.map(f => (
						<TaskFileItem file={f} taskId={taskId} key={f.id} className="task-file-list__item" />
					))}
				</div>
			</div>
		);
	}

	const val =
		field.type === XShemaFormItemType.Enum
			? JSON.parse(field.options).options.find((x: { id: number }) => x.id === parseInt(field.value, 10))!.name
			: field.value;

	return <LabelValueRow value={val} label={field.name} vertical labelClassName="org-panel-data-row__label--auto" />;
}

interface ITaskItemProps {
	historyItem: XHistoryItem;
}

const TaskItem = observer(({ historyItem }: ITaskItemProps) => {
	return (
		<div>
			{historyItem.tasks.map(task => (
				<Row key={task.id}>
					<Column borderless>
						{task.values.map(field => (
							<TaskFieldValue key={field.id} field={field} taskId={task.id} />
						))}
					</Column>
					<Column>
						<Row>
							<Column borderless />
							<Column>
								<div className="task-history-info">
									<LabelValueRow value={fullName(task.executor)} label="Кем выполнено" vertical />
									<LabelValueRow
										value={formatDateTime(task.execute)}
										label="Когда выполнено"
										vertical
									/>
								</div>
							</Column>
						</Row>
					</Column>
				</Row>
			))}
		</div>
	);
});

export const TaskState = observer(({ className, task }: { className?: string; task: XProcessRef }) => {
	return (
		<div
			className={cn(className, "task-state", {
				"task-state--expired": isExpired(task.expire),
				"task-state--completed": task.is_finish,
			})}
		>
			{task.state}
		</div>
	);
});

interface Props {
	task: XProcessTaskInfo | XProcessAdminInfo;
	task1?: ITask | null;
	onBack: () => void;
}

function MultiPanel({ items }: { items: XHistoryItem[] }) {
	const [activeSection, setActiveSection] = React.useState<XTaskInfo | null>(null);
	const stateRef = React.useRef<string>();
	const sectionsRef = React.useRef<any>();
	const dataRef = React.useRef<TabbedControlData>();

	React.useEffect(() => {
		const state = Array.from(new Set(items.map(x => x.state.state))).join("; ");
		const sections = new Map(items.map(item => [item.tasks[0].id, item.tasks[0]]));

		stateRef.current = state;
		sectionsRef.current = sections;

		dataRef.current = {
			title: state,
			items: items.map(x => {
				const rootField = x.tasks[0].values[0];
				const itemLabel =
					rootField.type === XShemaFormItemType.Enum
						? JSON.parse(rootField.options).options.find(
								(x: { id: number }) => x.id === parseInt(rootField.value, 10),
						  )!.name
						: rootField.value;

				return {
					groupName: itemLabel,
					key: x.tasks[0].id,
					items: [],
				};
			}),
		};

		setActiveSection(sections.get(items[0].tasks[0].id) ?? null);
	}, []);

	function handleContentFetch(key: number) {
		setActiveSection(sectionsRef.current.get(key));
	}

	return (
		<div className="task-sub-item">
			<div className="task-sub-item-connector" />
			<Panel header={`${stateRef.current}`}>
				{dataRef.current && (
					<PanelTabsSection2 data={dataRef.current} onContentFetch={handleContentFetch}>
						{activeSection && (
							<div>
								{activeSection.values.map(field => (
									<TaskFieldValue field={field} taskId={activeSection.id} key={field.id} />
								))}
							</div>
						)}
					</PanelTabsSection2>
				)}
			</Panel>
		</div>
	);
}

function TaskPanel({ items }: { items: XHistoryItem[] }) {
	if (items.length === 1) {
		const t = items[0];

		return (
			<div className="task-sub-item">
				<div className="task-sub-item-connector" />
				<Panel header={`${t.state.state}`}>
					<PanelSection>
						<TaskItem historyItem={t} />
					</PanelSection>
				</Panel>
			</div>
		);
	}

	return <MultiPanel items={items} />;
}

export const TaskContent = observer(({ task, task1 = null, onBack }: Props) => {
	const header = (
		<div className="task-header">
			<BackButton onClick={onBack} />
			<div className="task-header__title">{task.scope.name}</div>
			<div className="task-header__state">
				<LabelValueRow label="Статус" value={task.state} vertical />
			</div>
		</div>
	);

	const mergedHistory: XHistoryItem[][] = [];
	let lastKey: number | null = null;
	const requestForQuotationsId = 2436;
	const registerOfGovernmentContractsId = 2437;
	let requestForQuotations: XHistoryItem[] | null = null;
	let registerOfGovernmentContracts: XHistoryItem[] | null = null;

	for (const h of task?.history) {
		if (h.formID === requestForQuotationsId && requestForQuotations !== null) {
			requestForQuotations.push(h);
			continue;
		} else if (h.formID === registerOfGovernmentContractsId && registerOfGovernmentContracts !== null) {
			registerOfGovernmentContracts.push(h);
			continue;
		} else if (h.formID === lastKey) {
			mergedHistory[mergedHistory.length - 1].push(h);
		} else {
			const arr = [h];
			mergedHistory.push(arr);

			if (h.formID === requestForQuotationsId) {
				requestForQuotations = arr;
			} else if (h.formID === registerOfGovernmentContractsId) {
				registerOfGovernmentContracts = arr;
			}
		}

		lastKey = h.formID;
	}

	return (
		<>
			<Panel header={header} expanded>
				<PanelSection>
					<Row>
						<Column borderless>
							<LabelValueRow value={task.schema} label="Тип сбора данных" />
							<LabelValueRow value={task.name} label="Название сбора данных" />
							<LabelValueRow
								value={formatDateTime(task.expire, DateTimeFormats.Until)}
								label="Срок обработки"
							/>
						</Column>
						<Column>
							<Row>
								<Column borderless>
									<LabelValueRow value={fullName(task.initiator)} label="Кем создана" vertical />
								</Column>
								<Column>
									<LabelValueRow value={formatDateTime(task.create)} label="Когда создана" vertical />
								</Column>
							</Row>
						</Column>
					</Row>
				</PanelSection>
			</Panel>
			<div>
				{task.base &&
					task.base.history.map(t => (
						<div className="task-sub-item" key={t.state.id}>
							<div className="task-sub-item-connector" />
							<Panel header={t.state.state}>
								<PanelSection>
									<TaskItem historyItem={t} />
								</PanelSection>
							</Panel>
						</div>
					))}
				{mergedHistory.map((t, index) => (
					<TaskPanel key={index} items={t} />
				))}
				{task1 !== null && (
					<div className="task-sub-item">
						<div className="task-sub-item-connector" />
						<Panel header={task.state} expanded>
							<PanelSection>
								<TaskItemAction item={task1} />
								<TaskActions item={task1} />
							</PanelSection>
						</Panel>
					</div>
				)}
			</div>
		</>
	);
});

export const Task = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const [fetched, setFetched] = React.useState(false);
		const { taskId } = useParams<{ taskId: string }>();
		const { tasksStore } = useContext(StoreContext);
		const [task, setTask] = React.useState<ITask>();
		const taskIdParam = parseInt(taskId, 10);

		React.useEffect(() => {
			tasksStore.fetchTask(taskIdParam).then(() => {
				setTask(
					new TaskModel(tasksStore.currentTask!, ({ next_id }: XNextTaskRef) => {
						if (next_id) {
							history.replace(generatePath(Paths.Task, { taskId: next_id }));
						} else {
							history.replace(generatePath(Paths.Tasks));
						}
					}),
				);
				setFetched(true);
			});
		}, [tasksStore, taskId]);

		function handleBack() {
			history.push(Paths.Tasks);
		}

		return (
			<>
				<AppHeader />
				<div className="app__content">
					<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
						{/*<div className="content-header">*/}
						{/*	<div className="content-header__title">Мои заявки</div>*/}
						{/*</div>*/}
						<div className={cn("documents-content")}>
							{tasksStore.loading || !fetched ? (
								<Overlay global={false} globalWait />
							) : (
								<TaskContent task={tasksStore.currentTask!} task1={task!} onBack={handleBack} />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}),
);
