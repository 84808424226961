import React, { useContext, useEffect } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { AppHeader } from "@rossotr/components/app-header";

import { StoreContext } from "../../context";
import { ScopeFileList } from "@app-components/files/scope-file-list";

export const Documents = observer(function() {
	const { documentsStore } = useContext(StoreContext);

	useEffect(() => {
		documentsStore.fetchPage();
	}, []);

	return (
		<>
			<AppHeader />
			<div className="app__content">
				<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
					<div className="content-header">
						<div className="content-header__title">Общие документы</div>
					</div>
					<div className={cn("documents-content")}>
						<ScopeFileList files={documentsStore.items} scopeId={documentsStore.scopeId} />
					</div>
				</div>
			</div>
		</>
	);
});
