import React from "react";
import cn from "classnames";
import { CaretIcon } from "@icons";

interface Props {
	children?: React.ReactNode;
	onExpanded?: () => void;
	className?: string;
	header?: React.ReactNode;
	expanded?: boolean;
	expandable?: boolean;
}

export function Panel({ header, children, className, onExpanded, expanded = false, expandable = true }: Props) {
	if (!expandable) {
		expanded = true;
	}

	const [collapsed, setCollapsed] = React.useState(!expanded);

	function handleClick() {
		if (!expandable) {
			return;
		}

		const collapsedState = !collapsed;

		setCollapsed(collapsedState);

		if (!collapsedState) {
			onExpanded?.();
		}
	}

	const headerElement = typeof header === "string" ? <div className="org-panel-info__title">{header}</div> : header;

	return (
		<div className={cn("org-panel-info", className)}>
			<div
				className={cn("org-panel-info__header", { "org-panel-info__header--collapsed": collapsed })}
				onClick={handleClick}
			>
				{headerElement}
				{expandable && (
					<div className="org-panel-info__caret">
						<CaretIcon className="org-panel-info__caret-icon" />
					</div>
				)}
			</div>
			{!collapsed && children}
		</div>
	);
}
