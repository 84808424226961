import { InfiniteDataSource } from "@app-models/data-source/infinite-data-source";
import { AppState } from "../app-state";
import { actions } from "@actions";
import { XFileRef } from "@external-types/file";
import { Scope } from "@rossotr/constants/external";

export class DocumentsStore extends InfiniteDataSource<XFileRef> {
	private appState: AppState;

	public constructor(appState: AppState) {
		super();

		this.appState = appState;
	}

	protected get session() {
		return this.appState.documents;
	}

	public get scopeId() {
		return Scope.Documents;
	}

	protected async fetch() {
		const scope = await actions.getScope(this.scopeId);

		return scope.files;
	}
}