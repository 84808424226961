import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { PanelTabsSection2 } from "@app-components/panel-test/panel-tabs-section2";
import { ContentWithPager } from "@app-components/content-with-pager";
import { Row } from "@app-components/panel-test/row";
import { Column } from "@app-components/panel-test/column";
import { ScopePanel } from "@app-components/scope-panel";

import { BizEducationSection } from "../../models/org-model/org-biz-model";
import { BizEducationSubSection } from "../../models/org-model/org-biz-model/biz-education-section";

interface Props {
	section: BizEducationSection;
}

const TabSectionContent = observer(({ section, scopeId }: { section: BizEducationSubSection; scopeId: number }) => {
	const contentSection = section.getContent(scopeId);

	if (!contentSection) {
		return <span>Нет данных</span>;
	}

	return <ContentWithPager section={contentSection} />;
});

export const BizEducationPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<>
				{section.infoSection !== null && (
					<PanelSection title={section.infoSection.title}>
						<Row>
							<Column>
								<PanelSection>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Количество обучающихся</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Дошкольное образование</div>
										<div>{section.infoSection.studentsBeforeSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Начальное общее образование</div>
										<div>{section.infoSection.studentsMiddleSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Основное общее образование</div>
										<div>{section.infoSection.studentsCommonSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Среднее общее образовани</div>
										<div>{section.infoSection.studentsMiddleSchool}</div>
									</div>
								</PanelSection>
							</Column>
							<Column>
								<PanelSection>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Кол-во классов/групп</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Дошкольное образование</div>
										<div>{section.infoSection.groupsBeforeSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Начальное общее образование</div>
										<div>{section.infoSection.groupsMiddleSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Основное общее образование</div>
										<div>{section.infoSection.groupsCommonSchool}</div>
									</div>
									<div className="org-panel-data-row">
										<div className="org-panel-data-row__label">Среднее общее образовани</div>
										<div>{section.infoSection.groupsMiddleSchool}</div>
									</div>
								</PanelSection>
							</Column>
						</Row>
					</PanelSection>
				)}

				{section.baseSection !== null && (
					<PanelSection title={section.baseSection.title}>
						<PanelTabsSection2
							data={section.baseSection.value!}
							onContentFetch={section.baseSection.fetchContent}
						>
							<TabSectionContent
								section={section.baseSection}
								scopeId={section.baseSection.activeContentScopeId}
							/>
						</PanelTabsSection2>
					</PanelSection>
				)}

				{section.additionalSection !== null && (
					<PanelSection title={section.additionalSection.title}>
						<PanelTabsSection2
							data={section.additionalSection.value!}
							onContentFetch={section.additionalSection.fetchContent}
						>
							<TabSectionContent
								section={section.additionalSection}
								scopeId={section.additionalSection.activeContentScopeId}
							/>
						</PanelTabsSection2>
					</PanelSection>
				)}
			</>
		</ScopePanel>
	);
});
