import { observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { TabbedControlData } from "@app-models/tabbed-control";
import { Section } from "@app-models/section";
import { AttributeEntry, mapAttributes } from "@helpers/scope-attributes";

export class TransferredSection extends Section {
	@observable public leasedScope?: TabbedControlData;
	@observable public transferredByOtherReasonsScope?: TabbedControlData;
	@observable public transferredForFreeScope?: TabbedControlData;
	@observable public transferredWithoutRegistrationScope?: TabbedControlData;

	public transferredToThirdParty: AttributeEntry[];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Передано");

		this.transferredToThirdParty = this.getAttributes([
			"Передано во временное пользование сторонним организациям (всего, м2)",
			"В том числе на основании договоров аренды (м2)",
			"В том числе на основании договоров безвозмездного пользования (м2)",
			"В том числе по иным основаниям (м2)",
		]);
	}

	public get isEmpty() {
		return this.transferredToThirdParty.length === 0;
	}

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.scope.id);

		for (const scope of scopes) {
			switch (scope.name.trim()) {
				case "Передано по иным основаниям": {
					const result = await actions.getScopeChildren(scope.id);

					runInAction(() => {
						this.transferredByOtherReasonsScope = {
							title: scope.name,
							items: result.map(scope => ({
								key: scope.id,
								groupName: scope.name,
								items: mapAttributes(scope.attrs, scope.type),
							})),
						};
					});
					break;
				}

				case "Передано без оформления права пользования": {
					const result = await actions.getScopeChildren(scope.id);

					runInAction(() => {
						this.transferredWithoutRegistrationScope = {
							title: scope.name,
							items: result.map(scope => ({
								key: scope.id,
								groupName: scope.name,
								items: mapAttributes(scope.attrs, scope.type),
							})),
						};
					});
					break;
				}

				case "Передано в безвозмездное пользование": {
					const result = await actions.getScopeChildren(scope.id);

					runInAction(() => {
						this.transferredForFreeScope = {
							title: scope.name,
							items: result.map(scope => ({
								key: scope.id,
								groupName: scope.name,
								items: mapAttributes(scope.attrs, scope.type),
							})),
						};
					});
					break;
				}

				case "Передано в аренду": {
					const result = await actions.getScopeChildren(scope.id);

					runInAction(() => {
						this.leasedScope = {
							title: scope.name,
							items: result.map(scope => ({
								key: scope.id,
								groupName: scope.name,
								items: mapAttributes(scope.attrs, scope.type),
							})),
						};
					});
					break;
				}
			}
		}

		await super.fetch();
	}
}
