import { action, computed, observable } from "mobx";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { AttrMapper, mapAttributes } from "@helpers/scope-attributes";

export class Section {
	@observable protected _fetched: boolean = false;
	@observable protected scope: XScopeChildInfo | XScopeCard;

	public constructor(scope: XScopeChildInfo | XScopeCard, private _title: string | null = null) {
		this.scope = scope;
	}

	public get title() {
		return this._title ?? this.scope.name;
	}

	public get id() {
		return this.scope.id;
	}

	public get files() {
		return this.scope.files;
	}

	public getAttributes(mapper: AttrMapper | null = null, excludeEmpty: boolean = false) {
		return mapAttributes(this.scope.attrs, this.scope.type, mapper).filter(
			item => !excludeEmpty || item.value !== "",
		);
	}

	public getAttribute(mapper: AttrMapper) {
		const result = this.getAttributes(mapper);

		return result.length > 0 ? result[0] : null;
	}

	@computed
	public get fetched() {
		return this._fetched;
	}

	@action
	private setFetched() {
		this._fetched = true;
	}

	public async fetch(): Promise<void> {
		this.setFetched();
	}
}
