import React from "react";
import { observer } from "mobx-react";
import { RoomInfoSection } from "../../models/org-model/org-object-model";
import { PanelTabsContent } from "@app-components/panel";
import { ContentWithPager } from "@app-components/content-with-pager";
import { ScopePanel } from "@app-components/scope-panel";

interface Props {
	section: RoomInfoSection;
}

export const RoomInfoPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			{section.content != null && (
				<PanelTabsContent data={section.content} onTabChange={section.fetchScopeContent}>
					{section.activeContent != null && <ContentWithPager section={section.activeContent} />}
				</PanelTabsContent>
			)}
		</ScopePanel>
	);
});
