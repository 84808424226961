import { observable } from "mobx";
import { XProcessRef, XProcessTaskInfo } from "@external-types/process";
import { Filters, DataSourceSession, FilterItem } from "@app-models/data-source";
import { FilterType } from "@app-constants/filter-type";

class TaskFilters extends Filters {
	public constructor() {
		super(true);

		const filterItem = new FilterItem({
			type: FilterType.Select,
			name: "schema",
		});

		this.items.push(filterItem);
	}

	protected createQuery() {
		const query = super.createQuery();

		return {
			...query,
			all: true,
			tree: true,
			type: "all",
		};
	}
}

export class ArchiveSession extends DataSourceSession<XProcessRef> {
	@observable public currentTask: XProcessTaskInfo | null = null;

	public filter: TaskFilters = new TaskFilters();
}
