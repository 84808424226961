import React from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, withRouter } from "react-router";
import fileDownload from "js-file-download";

import { actions } from "@actions";
import { DocIcon, DownloadIcon } from "@icons";
import { XFileRef } from "@external-types/file";
import { Loader } from "@app-components/controls/loader";
import { Dialog } from "@app-components/dialog";

import { Paths } from "../../routes/paths";
import { SimpleOrgModel } from "../../models/simple-org-model";

enum AttrValueType {
	Email = 309,
	Url = 308,
}

const OrgDocuments = observer(function({ item }: { item: SimpleOrgModel }) {
	if (item.documents === null) {
		return <Loader />;
	}

	if (item.documents.length === 0) {
		return <div className="org-info-row__label">Отсутствуют</div>;
	}

	function handleDownload(scope: XFileRef) {
		return async function() {
			const data = await actions.getScopeFileContent(item.documentsScopeId, scope.id);

			fileDownload(data, scope.filename, scope.mimetype);
		};
	}

	return (
		<>
			{item.documents.map(d => (
				<div key={d.id} onClick={handleDownload(d)} className="org-document">
					<DocIcon className="org-document__doc-icon" />
					<span className="org-document__name">{d.filename}</span>
					<DownloadIcon className="org-document__download-icon" />
				</div>
			))}
		</>
	);
});

const AttrValueContent = function({ id, value }: { id: number; value: string }) {
	if (id === AttrValueType.Email) {
		return <a href={`mailto:${value}`}>{value}</a>;
	}

	if (id === AttrValueType.Url) {
		return (
			<a href={value} target="_blank">
				{value}
			</a>
		);
	}

	return <>{value}</>;
};

interface Props extends RouteComponentProps {
	item: SimpleOrgModel;
	onClose(): void;
}

export const OrgCardDialog = withRouter(
	observer(function({ item, onClose, history }: Props) {
		React.useEffect(() => {
			item.fetchDocuments();
		}, []);

		function onMore() {
			history.replace(Paths.Orgs + "/" + item.id);
		}

		return (
			<Dialog onClose={onClose} className="org-details-dialog">
				<div className="org-details-dialog__header">
					<div
						className="org-details-dialog__header-logo"
						style={{ background: `url(${item.logoUrl}) no-repeat`, backgroundSize: "cover" }}
					/>
					{item.fullName}
				</div>
				<div className="org-details-dialog__content">
					<div className="org-info">
						<div className="org-info__header">Общие документы</div>
						<OrgDocuments item={item} />
					</div>
					<div className="org-info">
						<div className="org-info__header">Данные организации</div>
						{item.companyInfo.map(({ key, label, value }) => (
							<div className="org-info-row" key={key}>
								<div className="org-info-row__label">{label}</div>
								<div className="org-info-row__value">{value}</div>
							</div>
						))}
					</div>
					<div className="org-info">
						<div className="org-info__header">Руководитель</div>
						{item.personalInfo.map(({ key, label, value }) => (
							<div className="org-info-row" key={key}>
								<div className="org-info-row__label">{label}</div>
								<div className="org-info-row__value">
									<AttrValueContent id={key} value={value} />
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="org-details-dialog__actions">
					<button type="button" className="button button--secondary" onClick={onClose}>
						Закрыть
					</button>

					<button type="button" className="button button--primary" onClick={onMore}>
						Посмотреть подробнее
					</button>
				</div>
			</Dialog>
		);
	}),
);
