import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router";

import { Overlay } from "@app-components/controls/overlay";
import { Path } from "@helpers/navigation";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopeFileItem } from "@app-components/files/scope-file-item";

import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "@rossotr/components/org-navigation";
import { OrgHeader } from "@rossotr/components/org-header";
import { StoreContext } from "../../context";
import { OrgModel } from "../../models/org-model";
import { ScopePanel } from "../../../../../src/components/scope-panel";

const Content = observer(({ org }: { org: OrgModel }) => {
	if (!org.orgReports) {
		return null;
	}

	return (
		<>
			<div className="sidebar-content__sub-header">Отчеты</div>
			<ScopePanel section={org.orgReports}>
				<PanelSection>
					<table className="org-reports">
						<tbody>
							<tr>
								<th>Название</th>
								<th>Отчетный период</th>
								<th>Отчет</th>
							</tr>
							{org.orgReports.files.map(x => (
								<tr key={x.id}>
									<td>{x.filename}</td>
									<td>2020</td>
									<td>
										<ScopeFileItem
											file={x}
											scopeId={org.orgReports!.reportsScopeId}
											caption="Скачать"
										/>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				</PanelSection>
			</ScopePanel>
		</>
	);
});

export const OrgReports = observer(function() {
	const [fetched, setFetched] = React.useState(false);
	const { id } = useParams<{ id: string }>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	React.useEffect(() => {
		if (!fetched) {
			orgStore.fetchOrg(orgId).then(() => setFetched(true));
		}
	}, [orgStore, orgId]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["reports"])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="reports" />
					<OrgHeader />
					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content org={orgStore.currentOrg!} />
					)}
				</div>
			</div>
		</>
	);
});
