import { observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { AttributeEntry } from "@helpers/scope-attributes";
import { Section } from "@app-models/section";
import { SectionContentWithPager } from "@app-models/section-content-with-pager";

export class SpecificationSection extends Section {
	@observable public currentRenovationScope?: SectionContentWithPager;
	@observable public previousRenovationScope?: SectionContentWithPager;
	public usageInfo: AttributeEntry[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Технические показатели");

		this.usageInfo = this.getAttributes([
			"Результаты наблюдений за зданием и его конструктивными элементами",
			"Результаты инструментальных замеров осадок, прогибов и других деформаций отдельных конструктивных элементов",
			"Основные заключения по результатам периодических осмотров здания",
			"Основные заключения по результатам периодических осмотров участка",
			"Сведения о нарушениях правил эксплуатации и о мерах по пресечению таких нарушений",
			"Основные данные о проведенных ремонтах (сроки, характер, объем)",
			"Основные данные о проведенных реконструкциях (сроки, характер)",
			"Примечание",
		]);
	}

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.scope.id);

		for (const scope of scopes) {
			switch (scope.name.trim()) {
				case "Капитальные ремонты предыдущих лет":
					const previousRenovationScope = new SectionContentWithPager(scope);

					runInAction(() => {
						this.previousRenovationScope = previousRenovationScope;
					});

					await previousRenovationScope.fetch();
					break;

				case "Текущие ремонты предыдущих лет":
					const currentRenovationScope = new SectionContentWithPager(scope);

					runInAction(() => {
						this.currentRenovationScope = currentRenovationScope;
					});

					await currentRenovationScope.fetch();
					break;
			}
		}

		await super.fetch();
	}
}
