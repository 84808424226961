import React from "react";
import { observer } from "mobx-react";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { PanelTabsSection1 } from "@app-components/panel-test/panel-tabs-section";
import { ScopePanel } from "@app-components/scope-panel";
import { TabbedSection } from "@app-models/section";

interface Props {
	section: TabbedSection;
}

export const BizByChartPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			{section.data !== null && (
				<PanelSection title="Вид деятельности, предусмотренный уставом">
					<PanelTabsSection1 data={section.data} />
				</PanelSection>
			)}
		</ScopePanel>
	);
});
