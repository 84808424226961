import { action, runInAction } from "mobx";
import { actions } from "@actions";
import { InfiniteDataSource } from "@app-models/data-source/infinite-data-source";
import { XProcessRef, XTaskList } from "@external-types/process";
import { AppState } from "./app-state";
import { TaskSchema } from "../constants/external/task-schema";

export class TasksStore extends InfiniteDataSource<XProcessRef> {
	private appState: AppState;

	public constructor(appState: AppState) {
		super();

		this.appState = appState;
	}

	public async fetchTask(taskId: number) {
		runInAction(() => {
			this.session.loading = true;
			this.session.currentTask = null;
		});

		const task = await actions.getTask(taskId);

		runInAction(() => {
			this.session.loading = false;
			this.session.currentTask = task;
		});
	}

	public get currentTask() {
		return this.session.currentTask;
	}

	protected get session() {
		return this.appState.tasks;
	}

	protected fetch = async () => {
		const result = await actions.getMyTasks(this.pagination, this.filter);

		return this.updatePageData(result);
	};

	@action
	private updatePageData = async (descendants: XTaskList) => {
		const schemaFilter = descendants.available.schema;
		if (schemaFilter) {
			descendants.available.schema = descendants.available.schema.filter(item =>
				[TaskSchema.DepartmentalControl, TaskSchema.DepartmentalControlDocumentCollection].includes(item.id),
			);
		}

		this.updatePageInfo(descendants);

		return descendants.list;
	};
}
