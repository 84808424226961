import React, { useContext, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { AppHeader } from "@rossotr/components/app-header";
import { StoreContext } from "../context";
import { inRole } from "@helpers/user";
import { Paths } from "../routes/paths";
import { Role } from "@rossotr/constants/external";

export const Home = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const { appStore } = useContext(StoreContext);

		useEffect(() => {
			if (appStore.authUser) {
				if (inRole(appStore.authUser.roles, Role.Executor)) {
					history.replace(generatePath(Paths.OrgInfo, { id: appStore.authUser.scopeID }));
				} else if (appStore.userSections.length > 0) {
					history.replace(appStore.userSections[0].path);
				}
			}
		}, [appStore.authUser]);

		return (
			<>
				<AppHeader />
				<div className="app__content" />
			</>
		);
	}),
);
