import { actions } from "@actions";

import { InfiniteDataSource } from "@app-models/data-source/infinite-data-source";

import { Scope } from "../../constants/external";
import { AppState } from "../app-state";
import { SimpleOrgModel } from "../../models/simple-org-model";

export class OrgsStore extends InfiniteDataSource<SimpleOrgModel> {
	private appState: AppState;

	public constructor(appState: AppState) {
		super();

		this.appState = appState;
	}

	protected get session() {
		return this.appState.orgs;
	}

	public get scopeId() {
		return Scope.Root;
	}

	protected async fetch() {
		const reportsList = await actions.getScopeChildrenPaged(this.scopeId, this.session.pagination, this.session.filter);

		this.updatePageInfo(reportsList);

		return reportsList.list.map(d => new SimpleOrgModel(d));
	}
}
