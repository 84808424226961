export enum ScopeCode {
	OrgTypeCode = "RIORG1",
	OrgReportsCode = "RIREP",

	Accreditation = "RIACCR",
	History = "RIHIST1",
	Staff = "RISTAFF1",
	Agreements = "RIDEAL1",
	Property = "RIPROPS1",
	Events = "RIEVENT1",

	Biz = "RIBIZ1",
	BizByCharter = "RIBIZ11",
	BizOtherInfo = "RIBIZ15",
	BizEducation = "RIBIZ12",
	BizProduction = "RIBIZ14",
	BizResultsScope = "RIBIZ13",

	BizResults = "RIBIZ132",
	BizEconomicIndicators = "RIBIZ131",

	Objects = "RIOBJ1",
	OrgDocuments = "RIDOC1",
}
