export enum Paths {
	Orgs = "/orgs",
	OrgInfo = "/orgs/:id",
	OrgProperty = "/org/:id/property",
	OrgAgreements = "/orgs/:id/agreements",
	OrgEvents = "/org/:id/events",
	OrgBiz = "/org/:id/biz",
	OrgDocuments = "/org/:id/documents",
	OrgTasks = "/org/:id/tasks",
	OrgTask = "/org/:id/tasks/:taskId",
	OrgObjects = "/org/:id/objects",
	OrgObjectsCategory = "/org/:id/objects/:category1/:category2",
	OrgObjectsCategoryItem = "/org/:id/objects/:category1/:category2/:objectId",
	OrgReports = "/org/:id/reports",

	Documents = "/documents",
	Tasks = "/tasks",
	Task = "/tasks/:taskId",
	Archive = "/archive",
	ArchiveTask = "/archive/:id",

	Home = "/",
	Login = "/login",
	CompleteAuth = "/complete-auth",
	AutoAuth = "/auto-auth",
}
