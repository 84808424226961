import { action, observable } from "mobx";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { actions } from "@actions";
import { Section } from "@app-models/section";

import { SpecificationSection } from "./specification-section";
import { ReviewResultsSection } from "./review-results-section";
import { RoomInfoSection } from "./room-info-section";
import { FinanceUsageSection } from "./finance-usage-section";
import { DescriptionSection } from "./description-section";
import { MainSection } from "./main-section";
import { RealEstateSection } from "./real-estate-section";
import { EconomicIndicatorsSection } from "./economic-indicators-section";
import { PlannedRenovationSection } from "./planned-renovation-section";
import { TransferredSection } from "./transferred-section";
import { FunctionalRoomSection } from "./functional-room-section";

export {
	MainSection,
	DescriptionSection,
	FinanceUsageSection,
	RoomInfoSection,
	ReviewResultsSection,
	SpecificationSection,
	RealEstateSection,
	EconomicIndicatorsSection,
	PlannedRenovationSection,
	TransferredSection,
	FunctionalRoomSection,
};

export class OrgObjectModel extends Section {
	public specificationSection: SpecificationSection;
	public descriptionSection: DescriptionSection;
	public mainSection: MainSection;
	public realEstateSection: RealEstateSection;
	public economicIndicatorsSection: EconomicIndicatorsSection;
	public transferredSection: TransferredSection;
	@observable public reviewResultsSection: ReviewResultsSection | null = null;
	@observable public roomInfoSection: RoomInfoSection | null = null;
	@observable public financeUsageSection: FinanceUsageSection | null = null;
	@observable public plannedRenovationSection: PlannedRenovationSection | null = null;
	@observable public functionalRoomSection: FunctionalRoomSection | null = null;

	public constructor(scope: XScopeCard) {
		super(scope);

		this.mainSection = new MainSection(scope);
		this.descriptionSection = new DescriptionSection(scope);
		this.specificationSection = new SpecificationSection(scope);
		this.realEstateSection = new RealEstateSection(scope);
		this.economicIndicatorsSection = new EconomicIndicatorsSection(scope);
		this.transferredSection = new TransferredSection(scope);
	}

	public async fetch() {
		const children = await actions.getScopeChildren(this.scope.id);

		this.mapScopes(children);
		await super.fetch();
	}

	@action
	private mapScopes(scopes: XScopeChildInfo[]) {
		const result: Section[] = [];

		for (const scope of scopes) {
			switch (scope.name.trim()) {
				case "Результаты осмотра инженерного оборудования": {
					this.reviewResultsSection = new ReviewResultsSection(scope);
					break;
				}
				case "Сведения о помещениях": {
					this.roomInfoSection = new RoomInfoSection(scope);
					break;
				}
				case "Отчет об освоении средств выделенных на капитальный (текущий) ремонт": {
					this.financeUsageSection = new FinanceUsageSection(scope);
					break;
				}
				case "Планируемые ремонтные работы":
				case "Планируемые строительные работы": {
					this.plannedRenovationSection = new PlannedRenovationSection(scope);
					break;
				}
				case "Функциональные помещения": {
					this.functionalRoomSection = new FunctionalRoomSection(scope);
					break;
				}
			}
		}

		return result;
	}
}
