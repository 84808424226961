import React from "react";
import { observer } from "mobx-react-lite";
import { RouteComponentProps, useParams, withRouter } from "react-router";
import cn from "classnames";

import { Overlay } from "@app-components/controls/overlay";
import { XProcessAdminInfo } from "@external-types/process";
import { actions } from "@actions";

import { Paths } from "../../routes/paths";
import { AppHeader } from "../../components/app-header";
import { TaskContent } from "../task";

export const ArchiveTask = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const [fetched, setFetched] = React.useState(false);
		const { id } = useParams<{ id: string }>();
		const [task, setTask] = React.useState<XProcessAdminInfo>();
		const idParam = parseInt(id, 10);

		async function fetch() {
			try {
				const resultTask = await actions.getProcessByManager(idParam);

				setTask(resultTask);
			} finally {
				setFetched(true);
			}
		}

		React.useEffect(() => {
			fetch();
		}, []);

		function handleBack() {
			history.push(Paths.Archive);
		}

		return (
			<>
				<AppHeader />
				<div className="app__content">
					<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
						{/*<div className="content-header">*/}
						{/*	<div className="content-header__title">Мои заявки</div>*/}
						{/*</div>*/}
						<div className={cn("documents-content")}>
							{!fetched ? (
								<Overlay global={false} globalWait />
							) : task != null ? (
								<TaskContent task={task} onBack={handleBack} />
							) : (
								"Заявка не найдена"
							)}
						</div>
					</div>
				</div>
			</>
		);
	}),
);
