import { action, observable, runInAction } from "mobx";
import { AttributeEntry, mapAttributes } from "@helpers/scope-attributes";
import { actions } from "@actions";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { XFileRef } from "@external-types/file";

interface Page {
	attrs: AttributeEntry[];
	files: XFileRef[];
	scopeId: number;
	name: string;
}

export class SectionContentWithPager {
	@observable public pages: Page[] = [];
	@observable public pageNumber: number = 0;

	public constructor(public scope: XScopeChildInfo | XScopeCard) {}

	public async fetch() {
		const pages = await actions.getScopeChildren(this.scope.id);

		runInAction(() => {
			this.pages = pages.map(p => ({
				attrs: mapAttributes(p.attrs, p.type),
				files: p.files,
				scopeId: p.id,
				name: p.name,
			}));
		});
	}

	public get activePage() {
		if (this.pages.length === 0 || this.pages.length < this.pageNumber) {
			return null;
		}

		return this.pages[this.pageNumber];
	}

	@action
	public setActivePage(pageNumber: number) {
		this.pageNumber = pageNumber;
	}
}
