export enum OrgScopeAttributes {
	FullName = 12967,
	ShortName = 12968,
	Country = 21437,
	FoundationYear = 23140,
	AddressRu = 12982,
	AddressForeign = 23141,
	Phone = 22611,
	Fax = 24209,
	Email = 22610,
	Website = 23144,

	ManagerFullName = 12983,
	ManagerPosition = 12984,
	ManagerAppointedBy = 12985,
	ManagerTermOfOffice = 12986,
	ManagerPhoneOffice = 12987,
	ManagerPhoneMobile = 12988,
	ManagerAppointmentOrderId = 12989,
	ManagerAppointmentOrderDate = 12990,
	ManagerContractType = 12991,
	ManagerContractId = 12992,
	ManagerContractDate = 12993,

	ChiefAccountantFullName = 12994,
	ChiefAccountantPosition = 12995,
	ChiefAccountantAppointedBy = 12996,
	ChiefAccountantTermOfOffice = 12997,
	ChiefAccountantPhoneOffice = 12998,
	ChiefAccountantPhoneMobile = 12999,
	ChiefAccountantAppointmentOrderId = 13000,
	ChiefAccountantAppointmentOrderDate = 13001,
	ChiefAccountantContractType = 13002,
	ChiefAccountantContractId = 13003,
	ChiefAccountantContractDate = 22613,

	Status = 22612,
	BankingDetails = 12972,
	NumberDateRCSC = 23147,
	MortgageRCSC = 23148,
	EngineeringProtection = 23151,
	Security = 23152,

	History= 13340,
}
