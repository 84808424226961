import { observable } from "mobx";
import { XProcessRef, XProcessTaskInfo } from "@external-types/process";
import { DataSourceSession, FilterItem, Filters } from "@app-models/data-source";
import { FilterType } from "../../../../../src/constants/filter-type";

export class TasksSession extends DataSourceSession<XProcessRef> {
	@observable public currentTask: XProcessTaskInfo | null = null;

	public filter: Filters;

	public constructor() {
		super();

		const filter = new Filters(true);
		const filterItem = new FilterItem({
			type: FilterType.Select,
			name: "schema",
		});

		filter.items.push(filterItem);

		this.filter = filter;
	}
}
