import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section } from "@app-models/section";
import { XFileRef } from "@external-types/file";
import { XScopeChildInfo } from "@external-types/scope";

import { ScopeCode } from "../../constants/external";

export class OrgReportsModel extends Section {
	@observable public _files: XFileRef[] = [];
	@observable public reportsScopeId: number = 0;

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Паспорт подведомственной организации");
	}

	public get files() {
		return this._files;
	}

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);
		const targetScope = scopes.find(scope => scope.type.code === ScopeCode.OrgReportsCode);

		if (targetScope) {
			runInAction(() => {
				this.reportsScopeId = targetScope.id;
				this._files = targetScope.files;
				this._files.sort((a, b) => a.filename.localeCompare(b.filename));
			});
		}

		await super.fetch();
	}
}
