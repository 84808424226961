import { AttributeEntry } from "@helpers/scope-attributes";
import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";

export class DescriptionSection extends Section {
	public info: AttributeEntry | null;
	public features: AttributeEntry[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Характеристика");

		this.features = this.getAttributes([
			"Балансовая стоимость (руб.)",
			"Материал стен",
			"Число этажей",
			"Объем строения (м3)",
			"Общая площадь строения (м2)",
			"Общая площадь (м2)",
			"Протяженность (периметр) (м)",
			"Наличие цокольного этажа / подвала",
			"Площадь цокольного этажа / подвала (м2)",
			"Наличие мансарды",
			"Площадь мансарды (м2)",
			"Площадь, арендованная у Посольства РФ (м2)",
			"в том числе представительские помещения (м2)",
			"в том числе подсобно-представительские помещения (м2)",
			"в том числе служебные помещения (м2)",
			"в том числе подсобно-служебные и технические помещения (м2)",
			"в том числе жилые помещения общей площадью (м2)",
			"Площадь кровли (м2)",
			"Количество убираемой площади (м2)",
			"Проводится ремонт и (или) реконструкция (м2)",
			"Проводится ремонт / реконструкция / строительство (м2)",
			"Фактическое использование",
			"Не используется (м2)",
		]);

		this.info = this.getAttribute(["Характеристика жилых помещений объекта"]);
	}

	public get isEmpty() {
		return (this.info == null || !this.info.value) && this.features.every(feature => !feature.value);
	}
}
