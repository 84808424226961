import React from "react";
import cn from "classnames";
import { GridIcon, ListIcon } from "@icons";

export enum Layout {
	Grid = "grid",
	List = "list",
}

interface Props {
	layout: Layout;
	onToggle: () => void;
}

export function LayoutSwitch({ layout, onToggle }: Props) {
	function handleClick() {
		onToggle();
	}

	return (
		<div className="layout-switch" onClick={handleClick}>
			<button
				type="button"
				className={cn("layout-switch__button", { "layout-switch__button--active": layout === Layout.Grid })}
			>
				<GridIcon className="layout-switch__icon" />
			</button>
			<button
				type="button"
				className={cn("layout-switch__button", { "layout-switch__button--active": layout === Layout.List })}
			>
				<ListIcon className="layout-switch__icon" />
			</button>
		</div>
	);
}
