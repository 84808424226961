import { inRole } from "@helpers/user";
import { AppState } from "./app-state";
import { OrgsStore } from "./orgs";
import { OrgStore } from "./org";
import { DocumentsStore } from "./documents";
import { XUserRole } from "@external-types/user";
import { TasksStore } from "./tasks-store";
import { AppStore, AuthStore } from "@shared/stores";
import { Paths } from "../routes/paths";
import { IUserSection } from "../../../../src/types/models";
import { Role } from "../constants/external";
import { IAppStores } from "../../../../src/types/stores";
import { ArchiveStore } from "./archive-store";

export interface IRossotrStores extends IAppStores {
	orgsStore: OrgsStore;
	orgStore: OrgStore;
	documentsStore: DocumentsStore;
	tasksStore: TasksStore;
	archiveStore: ArchiveStore;
}

const sections: IUserSection[] = [
	{
		path: Paths.Orgs,
		title: "Организации",
		access(roles: XUserRole[]) {
			return inRole(roles, Role.Admin);
		},
	},
	{
		path: Paths.Tasks,
		title: "Ведомственный контроль",
		access(roles: XUserRole[]) {
			return inRole(roles, Role.Admin);
		},
	},
	{
		path: Paths.Archive,
		title: "Архив",
		access(roles: XUserRole[]) {
			return inRole(roles, Role.Admin);
		},
	},
	{
		path: Paths.Documents,
		title: "Общие документы",
		access(roles: XUserRole[]) {
			return inRole(roles, Role.Admin);
		},
	},
	// {
	// 	// @ts-ignore
	// 	path: "#4",
	// 	title: "Сводные отчеты",
	// 	access(roles: XUserRole[]) {
	// 		return inRole(roles, Role.Admin);
	// 	},
	// },
];

export function createStores() {
	const appState = new AppState();
	const appStore = new AppStore(appState, sections);

	const stores: IRossotrStores = {
		appStore,
		authStore: new AuthStore(appStore),
		orgsStore: new OrgsStore(appState),
		orgStore: new OrgStore(appState),
		documentsStore: new DocumentsStore(appState),
		tasksStore: new TasksStore(appState),
		archiveStore: new ArchiveStore(appState),
	};

	return stores;
}
