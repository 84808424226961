import { observable } from "mobx";
import { actions } from "@actions";
import { Section } from "@app-models/section";
import { SectionContentWithPager } from "@app-models/section-content-with-pager";

export class BizProductionSection extends Section {
	@observable public sections: SectionContentWithPager[] = [];

	public async fetch() {
		const sections = await actions.getScopeChildren(this.id);

		this.sections = sections.map(section => new SectionContentWithPager(section));

		for (const section of this.sections) {
			await section.fetch();
		}

		await super.fetch();
	}
}