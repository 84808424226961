import React from "react";
import { observer } from "mobx-react-lite";

import { SimpleOrgModel } from "../../models/simple-org-model";
import { OrgCardDialog } from "./org-dialog";
import { useFitText } from "@shared/hooks/use-fit-text";

export const OrgGridItem = observer(function({ item }: { item: SimpleOrgModel }) {
	const { fontSize, ref } = useFitText();

	const [showDetails, toggleShowDetails] = React.useState(false);

	function handleClose() {
		toggleShowDetails(false);
	}

	function handleShowDetails() {
		toggleShowDetails(true);
	}

	return (
		<>
			<div className="org-card org-card--grid" onClick={handleShowDetails}>
				<div className="org-card__content org-card__content--grid">
					<div
						className="org-card__icon org-card__icon--grid"
						style={{ backgroundImage: `url(${item.logoUrl})` }}
					/>
					<div ref={ref} className="org-card-fit-text" style={{ fontSize }}>
						{item.name}
					</div>
				</div>
				<div className="org-card__content-hover">
					<div className="org-card__content-hover-header">Полное название</div>
					{item.fullName}
				</div>
			</div>
			{showDetails && <OrgCardDialog item={item} onClose={handleClose} />}
		</>
	);
});
