import { observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeCard, XScopeChildInfo } from "@external-types/scope";
import { XFileRef } from "@external-types/file";
import { getScopeAttributeValue, mapAttributes, AttrMapper } from "@helpers/scope-attributes";
import { OrgScopeAttributes } from "../constants/external/org-scope-attributes";

export class SimpleOrgModel {
	@observable public card: XScopeCard | null = null;
	@observable public documents: XFileRef[] | null = null;
	@observable public documentsScopeId: number = 0;

	public constructor(private scopeRef: XScopeChildInfo) {}

	public get logoUrl() {
		return "/assets/logo.png";
	}

	public async fetchDocuments() {
		const id = this.scopeRef.id;
		const orgScopeChildren = await actions.getScopeChildren(id);
		const reportsScopeRef = orgScopeChildren.find(ch => ch.type.code === "REP");

		if (reportsScopeRef) {
			const reportsScopeChildren = await actions.getScopeChildren(reportsScopeRef.id);
			const modalReportsRef = reportsScopeChildren.find(ch => ch.type.code === "MOD");

			if (modalReportsRef) {
				const modalReports = await actions.getScope(modalReportsRef.id);

				runInAction(() => {
					this.documents = modalReports.files;
					this.documentsScopeId = modalReports.id;
				});
				return;
			}
		}

		runInAction(() => {
			this.documents = [];
		});
	}

	public get loaded() {
		return this.card !== null;
	}

	public get name() {
		return this.scopeRef.name;
	}

	public get id() {
		return this.scopeRef.id;
	}

	public get fullName() {
		return getScopeAttributeValue(this.scopeRef, OrgScopeAttributes.FullName);
	}

	public get companyInfo() {
		const mapper: AttrMapper = [
			[OrgScopeAttributes.AddressRu, "Метонахождение"],
			[OrgScopeAttributes.Phone, null],
			[OrgScopeAttributes.Fax, null],
			[OrgScopeAttributes.Email, null],
			[OrgScopeAttributes.Website, "Сайт"],
		];

		return mapAttributes(this.scopeRef.attrs, this.scopeRef.type, mapper);
	}

	public get personalInfo() {
		const mapper: AttrMapper = [
			[OrgScopeAttributes.ManagerFullName, "Ф.И.О."],
			[OrgScopeAttributes.ManagerPosition, "Должность"],
			[OrgScopeAttributes.ManagerPhoneMobile, "Телефон мобильный"],
			[OrgScopeAttributes.ManagerPhoneOffice, "Телефон рабочий"],
		];

		return mapAttributes(this.scopeRef.attrs, this.scopeRef.type, mapper);
	}
}
