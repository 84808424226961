import React, { useContext } from "react";
import { StoreContext } from "../../context";

interface Props {
	children?: React.ReactNode;
}

export function OrgHeader({ children }: Props) {
	const { orgStore } = useContext(StoreContext);
	const currentOrg = orgStore.currentOrg;

	if (!currentOrg) {
		return null;
	}

	return (
		<div className="sidebar-content__header">
			<div
				className="org-card__icon"
				style={{ background: `url(${currentOrg.logoUrl}) center/cover no-repeat` }}
			/>
			{currentOrg.name}
			{children && <div style={{ marginLeft: "auto" }}>{children}</div>}
		</div>
	);
}
