import React from "react";
import { observer } from "mobx-react-lite";

import { Overlay } from "@app-components/controls/overlay";
import { SchemaForm } from "@app-components/schema-form";
import { CheckBoxListWithPaging } from "@app-components/checkbox-list-wth-paging";

import { ITask } from "./task";

export const TaskItemAction = observer(function({ item }: { item: ITask }) {
	function handleSelectScopes(selected: number[]) {
		item.updateActionScopes(selected);
	}

	return (
		<div className="task-form">
			{item.actionIsExecuting && <Overlay inline />}

			<div className="form">
				<SchemaForm form={item.form} />
			</div>
			{item.scopeDataSource.items.length > 0 && (
				<div style={{ margin: "2rem 0" }}>
					<CheckBoxListWithPaging
						dataSource={item.scopeDataSource}
						onChange={handleSelectScopes}
						selected={item.selectedActionScopes}
					/>
				</div>
			)}
		</div>
	);
});
