import React from "react";
import cn from "classnames";

interface Props {
	children: React.ReactNode;
	active?: boolean;
	expanded?: boolean;
	onClick: () => void;
	level: number;
}

export function OrgNavigationSectionItem({ children, active = false, expanded = false, level, onClick }: Props) {
	return (
		<div
			className={cn("sidebar-section-item", `sidebar-section-item--level-${level}`, {
				"sidebar-section-item--active": active,
				"sidebar-section-item--expanded": expanded,
			})}
			onClick={onClick}
		>
			{children}
		</div>
	);
}
