import { action, computed, observable, runInAction } from "mobx";
import { actions } from "@actions";
import { Section } from "./section";

export class PagedSection extends Section {
	@observable public children: Section[] = [];
	@observable public pageNumber: number = 0;

	public async fetch() {
		const children = await actions.getScopeChildren(this.id);

		runInAction(() => {
			this.children = children.map(child => new Section(child));
		});
	}

	@computed
	public get activeSection() {
		if (this.children.length === 0 || this.children.length < this.pageNumber) {
			return null;
		}

		return this.children[this.pageNumber];
	}

	@action
	public setActiveSection(pageNumber: number) {
		this.pageNumber = pageNumber;
	}
}
