import React from "react";
import cn from "classnames";
import { CaretIcon } from "@icons";

interface Props {
	title: string;
	children?: React.ReactNode;
	onExpanded?: () => void;
	className?: string;
}

export function OrgPanel({ title, children, className, onExpanded }: Props) {
	const [collapsed, setCollapsed] = React.useState(true);

	function handleClick() {
		const collapsedState = !collapsed;

		setCollapsed(collapsedState);

		if (!collapsedState) {
			onExpanded?.();
		}
	}

	return (
		<div className={cn("org-panel-info", className)}>
			<div
				className={cn("org-panel-info__header", { "org-panel-info__header--collapsed": collapsed })}
				onClick={handleClick}
			>
				<div className="org-panel-info__title">{title}</div>
				<div className="org-panel-info__caret">
					<CaretIcon className="org-panel-info__caret-icon" />
				</div>
			</div>
			{!collapsed && children}
		</div>
	);
}
