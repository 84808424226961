import { Section } from "@app-models/section";
import { AttributeEntry, mapAttributes } from "@helpers/scope-attributes";
import { XScopeChildInfo } from "@external-types/scope";

export class StaffSection extends Section {
	public items: AttributeEntry[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Численность сотрудников");

		this.items = mapAttributes(scope.attrs, scope.type);
	}
}