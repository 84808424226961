import * as React from "react";
import { AppHeader as SharedAppHeader } from "@shared/app";
import Logo from "./logo";

function LogoPart() {
	return (
		<a href="/" className="header-logo">
			<span className="header-logo__image">
				<Logo />
			</span>
			<span className="header-logo__title">РОССОТРУДНИЧЕСТВО</span>
		</a>
	);
}

export function AppHeader() {
	return <SharedAppHeader Logo={LogoPart} />;
}
