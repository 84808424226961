import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section } from "@app-models/section";
import { XFileRef } from "@external-types/file";
import { XScopeChildInfo } from "@external-types/scope";

import { ScopeCode } from "../../constants/external";

export class OrgDocumentsModel extends Section {
	@observable public _files: XFileRef[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Документы");
	}

	public get files() {
		return this._files;
	}

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);
		const targetScope = scopes.find(scope => scope.type.code === ScopeCode.OrgDocuments);

		if (targetScope) {
			runInAction(() => {
				this._files = targetScope.files;
			});
		}

		await super.fetch();
	}
}
