import React from "react";
import { SvgIcon } from "@app-types/models";

export default function({ className }: SvgIcon) {
	return (
		<svg viewBox="0 0 28 25" fill="currentColor" className={className}>
			<g opacity="0.5">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M27.2026 7.68768V24.2458C27.2026 24.5723 26.9378 24.8371 26.6112 24.8371H0.59136C0.264784 24.8371 0 24.5723 0 24.2458V7.68768C0 7.3611 0.264784 7.09632 0.59136 7.09632H11.2358V0.59136C11.2358 0.264784 11.5006 0 11.8272 0H15.3754C15.7019 0 15.9667 0.264784 15.9667 0.59136V7.09632H26.6112C26.9378 7.09632 27.2026 7.3611 27.2026 7.68768ZM14.784 3.87705V1.18272H12.4186V3.87705C13.1462 3.43844 14.0563 3.43844 14.784 3.87705ZM13.6013 4.73088C14.2544 4.73088 14.784 5.26045 14.784 5.9136V9.46176H12.4186V5.9136C12.4186 5.26045 12.9481 4.73088 13.6013 4.73088ZM1.18272 23.6544H26.0198V8.27904H15.9667V9.46176H17.1494V10.6445H10.0531V9.46176H11.2358V8.27904H1.18272V23.6544Z"
					fill="black"
				/>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M13.6013 11.8271H2.95678C2.6302 11.8271 2.36542 12.0919 2.36542 12.4185V21.8803C2.36542 22.2068 2.6302 22.4716 2.95678 22.4716H13.6013C13.9278 22.4716 14.1926 22.2068 14.1926 21.8803V12.4185C14.1926 12.0919 13.9278 11.8271 13.6013 11.8271ZM5.97277 21.2889C6.1518 20.3883 6.73652 19.6205 7.55685 19.2079C7.75609 19.1074 7.8817 18.9033 7.8817 18.6801C7.8817 18.4571 7.75609 18.253 7.55685 18.1522C6.88118 17.8161 6.52544 17.059 6.6984 16.3241C6.87107 15.5896 7.52654 15.0701 8.28133 15.0701C9.03612 15.0701 9.69187 15.5896 9.86454 16.3241C10.0372 17.059 9.68148 17.8161 9.0058 18.1522C8.80656 18.253 8.68125 18.4571 8.68125 18.6801C8.68125 18.9033 8.80656 19.1074 9.0058 19.2079C9.82441 19.6214 10.4071 20.3892 10.5853 21.2889H5.97277ZM11.781 21.2889H13.0099V13.0099H3.54814V21.2889H4.77706C4.92057 20.254 5.44956 19.3113 6.25835 18.6497C5.75188 18.1297 5.4689 17.4318 5.47006 16.7059C5.47006 15.1544 6.72756 13.8969 8.27902 13.8969C9.83047 13.8969 11.088 15.1544 11.088 16.7059C11.09 17.4315 10.8082 18.1291 10.3026 18.6497C11.1102 19.3118 11.6383 20.2543 11.781 21.2889Z"
					fill="black"
				/>
				<path d="M16.5581 17.7407H24.8371V18.9234H16.5581V17.7407Z" fill="black" />
				<path d="M16.5581 14.1929H24.8371V15.3756H16.5581V14.1929Z" fill="black" />
				<path d="M23.6544 21.2891H24.8371V22.4718H23.6544V21.2891Z" fill="black" />
				<path d="M21.2889 21.2891H22.4717V22.4718H21.2889V21.2891Z" fill="black" />
				<path d="M18.9235 21.2891H20.1062V22.4718H18.9235V21.2891Z" fill="black" />
				<path d="M16.5581 21.2891H17.7408V22.4718H16.5581V21.2891Z" fill="black" />
			</g>
		</svg>
	);
}
