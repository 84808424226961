import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopeFileList } from "@app-components/files/scope-file-list";
import { ScopePanel } from "@app-components/scope-panel";
import { Section } from "@app-models/section";

interface Props {
	section: Section;
}

export const BizIndicatorsPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<PanelSection>
				<p className="panel-text">
					Раздел заполняется в формате представления данных бухгалтерского учета за отчетный год. <br /> Для
					унитарных предприятий подлежат заполнению формы "бухгалтерский баланс" и "отчет о финансовых
					результатах".
				</p>

				<div className="panel-mini-header" style={{ marginBottom: 20 }}>
					Документы
				</div>
				<div style={{ display: "inline-block" }}>
					<ScopeFileList files={section.files} scopeId={section.id} />
				</div>
			</PanelSection>
		</ScopePanel>
	);
});
