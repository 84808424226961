import React, { useContext, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { observer } from "mobx-react-lite";
import cn from "classnames";
import { StoreContext } from "../context";

export const Login = withRouter(
	observer(function(props: RouteComponentProps) {
		const [username, setUserName] = useState("");
		const [password, setPassword] = useState("");
		const { authStore } = useContext(StoreContext);

		React.useEffect(() => {
			authStore.fetchExternalAuths();
		}, []);

		function handleUsernameChange(e: React.FormEvent<HTMLInputElement>) {
			setUserName(e.currentTarget.value);
		}

		function handlePasswordChange(e: React.FormEvent<HTMLInputElement>) {
			setPassword(e.currentTarget.value);
		}

		function handleLogin() {
			const { history } = props;

			authStore
				.login({
					username,
					password,
				})
				.then(() => {
					history.replace("/");
				});
		}

		function handleSubmit(e: React.FormEvent) {
			e.preventDefault();
		}

		const hasError = authStore.errorMessage != null;

		return (
			<div className="login-page">
				<form className={cn("login-form", { "login-form--error": hasError })} onSubmit={handleSubmit}>
					<div className={"login-form__header"}>Вход в систему</div>

					{hasError && <div className="form__error-message">{authStore.errorMessage}</div>}

					<div className="form__group">
						<div className="form__row">
							<label className="form__label">Имя пользователя</label>
							<input
								className="form__input"
								type="text"
								onChange={handleUsernameChange}
								placeholder="Введите логин"
							/>
						</div>
						<div className="form__row">
							<label className="form__label">Пароль</label>
							<input
								className="form__input"
								type="password"
								onChange={handlePasswordChange}
								placeholder="Введите пароль"
							/>
						</div>
					</div>
					<div className="form__group">
						<button onClick={handleLogin} type="submit" className="button button--primary">
							Вход
						</button>
					</div>
				</form>
			</div>
		);
	}),
);
