import { AttributeEntry } from "@helpers/scope-attributes";
import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";

export class EconomicIndicatorsSection extends Section {
	public items: AttributeEntry[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Экономические показатели");

		this.items = this.getAttributes(
			[
				"Сумма страховки в год",
				"Дополнительные сведения о страховке",
				"Затраты на содержание",
				"Дополнительные сведения о затратах на содержание",
				"Другие затраты",
				"Дополнительные сведения о других затратах",
			],
			true,
		);
	}

	public get isEmpty() {
		return this.items.length === 0;
	}
}
