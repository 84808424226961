import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section, TabbedSection } from "@app-models/section";
import { ScopeCode } from "../../constants/external";

export class OrgPropertyModel extends Section {
	@observable public children: TabbedSection[] = [];

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);
		const targetScope = scopes.find(scope => scope.type.code === ScopeCode.Property);

		if (targetScope) {
			const children = await actions.getScopeChildren(targetScope.id);

			runInAction(() => {
				this.children = children.map(scope => new TabbedSection(scope));
			});
		}

		await super.fetch();
	}
}