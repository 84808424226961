import React from "react";
import cn from "classnames";
import { XFileRef } from "@external-types/file";
import { fileIcon } from "@app-components/files/file-icon";
import { downloadScopeFile } from "@helpers/downloader";

interface Props {
	file: XFileRef;
	scopeId: number;
	caption?: string;
	className?: string;
}

export function ScopeFileItem({ file, scopeId, caption, className }: Props) {
	const Icon = fileIcon(file.filename, file.mimetype);

	function handleDownload() {
		downloadScopeFile(scopeId, file);
	}

	return (
		<div className={cn("file-item", className)} onClick={handleDownload}>
			<Icon className="file-preview-list__file-icon" />
			{caption ?? file.filename}
		</div>
	);
}
