import React from "react";
import { Route, Switch } from "react-router-dom";

import { PrivateRoute } from "@app-components/routes";
import { Role } from "../constants/external";
import * as Pages from "../pages";
import { Paths } from "./paths";

export function Routes() {
	return (
		<Switch>
			<PrivateRoute exact path={Paths.Home} component={Pages.Home} />
			<PrivateRoute exact path={Paths.OrgInfo} component={Pages.OrgInfo} />
			<PrivateRoute exact path={Paths.OrgAgreements} component={Pages.OrgAgreements} />
			<PrivateRoute exact path={Paths.OrgProperty} component={Pages.OrgProperty} />
			<PrivateRoute exact path={Paths.OrgEvents} component={Pages.OrgEvents} />
			<PrivateRoute exact path={Paths.OrgBiz} component={Pages.OrgBiz} />
			<PrivateRoute exact path={Paths.OrgDocuments} component={Pages.OrgDocuments} />
			<PrivateRoute exact path={Paths.OrgTasks} component={Pages.OrgTasks} />
			<PrivateRoute exact path={Paths.OrgTask} component={Pages.OrgTask} />
			<PrivateRoute exact path={Paths.OrgObjectsCategory} component={Pages.OrgObjects} />
			<PrivateRoute exact path={Paths.OrgObjectsCategoryItem} component={Pages.OrgObjectItem} />
			<PrivateRoute exact path={Paths.OrgReports} component={Pages.OrgReports} />

			<PrivateRoute exact path={Paths.Orgs} component={Pages.Orgs} roles={[Role.Admin]} />
			<PrivateRoute exact path={Paths.Documents} component={Pages.Documents} roles={[Role.Admin]} />

			<PrivateRoute exact path={Paths.Tasks} component={Pages.Tasks} roles={[Role.Admin]} />
			<PrivateRoute exact path={Paths.Task} component={Pages.Task} roles={[Role.Admin]} />

			<PrivateRoute exact path={Paths.Archive} component={Pages.Archive} roles={[Role.Admin]} />
			<PrivateRoute exact path={Paths.ArchiveTask} component={Pages.ArchiveTask} roles={[Role.Admin]} />

			<Route path={Paths.Login} component={Pages.Login} />
			<Route path={Paths.CompleteAuth} component={Pages.CompleteAuth} />
			<Route path={Paths.AutoAuth} component={Pages.AutoAuth} />
			<Route path="*" component={Pages.NotFound} />
		</Switch>
	);
}
