import React, { useContext, useEffect } from "react";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { observer } from "mobx-react-lite";
import cn from "classnames";

import { XProcessRef } from "@external-types/process";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { DateTimeFormats, formatDateTime } from "@helpers/datetime";

import { StoreContext } from "../../context";
import { AppHeader } from "../../components/app-header";
import { Paths } from "../../routes/paths";
import { TaskState } from "../task";
import { TaskSchema } from "../../constants/external/task-schema";

interface ItemProps extends RouteComponentProps {
	item: XProcessRef;
}

const TaskItem = withRouter(
	observer(({ item, history }: ItemProps) => {
		function handleClick() {
			history.push(generatePath(Paths.ArchiveTask, { id: item.pid }));
		}

		return (
			<div className="org-lawsuits__row" style={{ width: "100%" }} onClick={handleClick}>
				<div className="org-lawsuits__cell org-lawsuits__cell--primary">{item.scope.name}</div>
				<div className="org-lawsuits__cell">{item.name}</div>
				<div className="org-lawsuits__cell">{item.schema}</div>
				<div className="org-lawsuits__cell">{formatDateTime(item.expire, DateTimeFormats.Until)}</div>
				<div className="org-lawsuits__cell">
					<TaskState task={item} />
				</div>
			</div>
		);
	}),
);

const Content = observer(({ items }: { items: XProcessRef[] }) => {
	return (
		<div className="org-lawsuits">
			<div className="org-lawsuits__caption">
				<div className="org-lawsuits__cell">Организация</div>
				<div className="org-lawsuits__cell">Название</div>
				<div className="org-lawsuits__cell">Тип</div>
				<div className="org-lawsuits__cell">Срок исполнения</div>
				<div className="org-lawsuits__cell">Статус</div>
			</div>

			{items.map(x => (
				<TaskItem key={x.pid} item={x} />
			))}
		</div>
	);
});

export const Archive = observer(function() {
	const { archiveStore } = useContext(StoreContext);
	const [fetched, setFetched] = React.useState(false);

	useEffect(() => {
		if (!fetched) {
			archiveStore.applyFilter("schema", TaskSchema.DepartmentalControl).then(() => setFetched(true));
		}
	}, [fetched]);

	function handleFilterApply(filterId: string, value: any) {
		archiveStore.applyFilter(filterId, value);
	}

	return (
		<>
			<AppHeader />
			<div className="app__content">
				<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
					<div className="content-header">
						<div className="content-header__title">Архив</div>
					</div>
					<div>
						<FilterToolbar
							filters={archiveStore.filter}
							onApply={handleFilterApply}
							hiddenFilters={["all", "tree", "type"]}
						/>
					</div>
					<div className={cn("documents-content")}>
						<Content items={archiveStore.items} />
					</div>
				</div>
			</div>
		</>
	);
});
