import { action } from "mobx";
import { actions } from "@actions";
import { InfiniteDataSource } from "@app-models/data-source/infinite-data-source";
import { XProcessRef, XTaskList } from "@external-types/process";
import { AppState } from "./app-state";
import { Scope } from "../constants/external";
import { TaskSchema } from "../constants/external/task-schema";

export class ArchiveStore extends InfiniteDataSource<XProcessRef> {
	private appState: AppState;

	public constructor(appState: AppState) {
		super();

		this.appState = appState;
	}

	protected get session() {
		return this.appState.archive;
	}

	protected fetch = async () => {
		const result = await actions.getScopeProcesses(Scope.Archive, this.pagination, this.filter);

		return this.updatePageData(result);
	};

	@action
	private updatePageData = async (descendants: XTaskList) => {
		const schemaFilter = descendants.available.schema;

		if (schemaFilter) {
			descendants.available.schema = descendants.available.schema.filter(item =>
				[TaskSchema.DepartmentalControl, TaskSchema.DepartmentalControlDocumentCollection].includes(item.id),
			);
		}

		this.updatePageInfo(descendants);

		return descendants.list;
	};
}
