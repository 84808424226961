import React from "react";
import { observer } from "mobx-react-lite";

import { SimpleOrgModel } from "../../models/simple-org-model";
import { OrgCardDialog } from "./org-dialog";

export const OrgListItem = observer(function({ item }: { item: SimpleOrgModel }) {
	const [showDetails, toggleShowDetails] = React.useState(false);

	function handleClose() {
		toggleShowDetails(false);
	}

	function handleShowDetails() {
		toggleShowDetails(true);
	}

	return (
		<>
			<div className="org-card org-card--list" onClick={handleShowDetails}>
				<div className="org-card__content org-card__content--list">
					<div
						className="org-card__icon org-card__icon--list"
						style={{ backgroundImage: `url(${item.logoUrl})` }}
					/>
					{item.name}
				</div>
				<div className="org-card__content-hover">
					<div className="org-card__content-hover-header">Полное название</div>
					{item.fullName}
				</div>
			</div>
			{showDetails && <OrgCardDialog item={item} onClose={handleClose} />}
		</>
	);
});
