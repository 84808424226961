import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@external-types/scope";
import { breakByLine } from "@helpers/text";

export class RealEstateSection extends Section {
	public items: string[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope, "Перечень расположенных на участке объектов недвижимости");

		const addressAttr = this.getAttribute(["Перечень расположенных на участке объектов недвижимости"]);

		if (addressAttr) {
			this.items = breakByLine(addressAttr.value);
		}
	}

	public get isEmpty() {
		return this.items.length === 0;
	}
}
