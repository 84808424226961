import React, { useContext } from "react";
import { CardIcon, FilesIcon, Folder2Icon, HospitalIcon, TaskIcon } from "@icons";
import { OrgNavigationSection } from "./org-navigation-section";
import { OrgNavigationSectionItem } from "./org-navigation-section-item";
import { Paths } from "../../routes/paths";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { SvgIcon } from "@app-types/models";
import { StoreContext } from "../../context";
import { Path } from "@helpers/navigation";
export { OrgBreadcrumb } from "./org-breadcrumb";

export enum SectionItemId {
	Basic = "basic",
	Biz = "biz",
	Property = "property",
	Agreements = "agreements",
	Events = "events",
}

interface CardSectionItem {
	id: string;
	items?: CardSectionItem[];
	name: string;
	url?: string;
	urlParams?: any;
}

interface OrgSection {
	Icon: React.ComponentType<SvgIcon>;
	id: Section;
	items?: CardSectionItem[];
	name: string;
	url?: string;
}

const cardSectionItems: CardSectionItem[] = [
	{ name: "Основные сведения", id: SectionItemId.Basic, url: Paths.OrgInfo },
	{ name: "Деятельность", id: SectionItemId.Biz, url: Paths.OrgBiz },
	{ name: "Имущественный комплекс", id: SectionItemId.Property, url: Paths.OrgProperty },
	{ name: "Соглашения", id: SectionItemId.Agreements, url: Paths.OrgAgreements },
	{ name: "Мероприятия", id: SectionItemId.Events, url: Paths.OrgEvents },
];

type Section = "card" | "reports" | "objects" | "lawsuits" | "tasks" | "documents";

interface Props extends RouteComponentProps {
	orgId: number;
	path: Path;
}

const orgSections: OrgSection[] = [
	{
		id: "card",
		name: "Карточка",
		Icon: CardIcon,
		items: cardSectionItems,
		url: Paths.OrgInfo,
	},
	{
		id: "reports",
		name: "Отчеты",
		Icon: Folder2Icon,
		url: Paths.OrgReports,
	},
	{
		id: "objects",
		name: "Объекты",
		Icon: HospitalIcon,
		//url: Paths.OrgObjects,
	},
	{
		id: "tasks",
		name: "Ведомственный контроль",
		Icon: TaskIcon,
		url: Paths.OrgTasks,
	},
	{
		id: "documents",
		name: "Документы",
		Icon: FilesIcon,
		url: Paths.OrgDocuments,
	},
];

export const OrgNavigation = withRouter(({ orgId, path: initialPath, history }: Props) => {
	const { orgStore } = useContext(StoreContext);
	const currentOrg = orgStore.currentOrg;
	const sections = currentOrg?.objects.sections;
	const [path, setPath] = React.useState(initialPath);

	React.useEffect(() => {
		if (sections) {
			const objectsSection = orgSections.find(section => section.id === "objects");

			if (objectsSection) {
				objectsSection.items = sections.map(section => ({
					id: section.id.toString(),
					name: section.name,
					items: currentOrg!.objects.getSubsections(section.id).map(x => ({
						id: x.id.toString(),
						name: x.name,
						url: Paths.OrgObjectsCategory,
						urlParams: { id: orgId, category1: section.id, category2: x.id },
					})),
				}));
			}
		}
	}, [sections]);

	function handleClickItem(item: CardSectionItem, level: number) {
		return function() {
			setPath(path.setActive(item.id, level));

			if (item.url) {
				history.push(generatePath(item.url, item.urlParams ?? { id: orgId }));
			}
		};
	}

	function handleClickSection(section: OrgSection) {
		return function() {
			setPath(path.setActive(section.id, 0));

			if (section.url) {
				history.push(generatePath(section.url, { id: orgId }));
			}
		};
	}

	return (
		<div className="sidebar">
			{orgSections.map(sectionItem => {
				const expanded = path.includes(sectionItem.id, 0);

				return (
					<OrgNavigationSection
						key={sectionItem.id}
						name={sectionItem.name}
						Icon={sectionItem.Icon}
						expanded={expanded}
						active={expanded && path.isActive(sectionItem.id)}
						onClick={handleClickSection(sectionItem)}
					>
						{expanded &&
							sectionItem.items != null &&
							sectionItem.items.map(subItem => {
								const { name, id } = subItem;
								const expanded = path.includes(id, 1);

								return (
									<React.Fragment key={id}>
										<OrgNavigationSectionItem
											expanded={expanded}
											active={expanded && path.isActive(id)}
											onClick={handleClickItem(subItem, 1)}
											level={1}
										>
											{name}
										</OrgNavigationSectionItem>
										{expanded &&
											subItem.items != null &&
											subItem.items.map(s => {
												const { name, id } = s;

												return (
													<OrgNavigationSectionItem
														active={path.includes(id, 2)}
														key={id}
														onClick={handleClickItem(s, 2)}
														level={2}
													>
														{name}
													</OrgNavigationSectionItem>
												);
											})}
									</React.Fragment>
								);
							})}
					</OrgNavigationSection>
				);
			})}
		</div>
	);
});
