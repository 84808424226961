import { observer } from "mobx-react";
import React from "react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopePanel } from "@app-components/scope-panel";

import { HistorySection } from "../../models/org-model/org-info-model";

interface Props {
	section: HistorySection;
}

export const HistoryPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<PanelSection>
				{section.value.map((str, index) => (
					<p key={index}>{str}</p>
				))}
			</PanelSection>
		</ScopePanel>
	);
});
