import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "@rossotr/components/org-navigation";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { StoreContext } from "../../context";
import { Overlay } from "@app-components/controls/overlay";
import { OrgHeader } from "@rossotr/components/org-header";
import { Path } from "@helpers/navigation";
import { XNextTaskRef } from "@external-types/process/next-task-ref";
import { Paths } from "../../routes/paths";
import { TaskContent } from "../task";
import { ITask, Task } from "../task/task-action/task";

export const OrgTask = withRouter(
	observer(function({ history }: RouteComponentProps) {
		const [fetched, setFetched] = React.useState(false);
		const { id, taskId } = useParams<{ id: string; taskId: string }>();
		const { orgStore } = useContext(StoreContext);
		const orgId = parseInt(id, 10);
		const taskIdParam = parseInt(taskId, 10);
		const [task, setTask] = React.useState<ITask>();

		React.useEffect(() => {
			orgStore.fetchOrgTask(orgId, taskIdParam).then(() => {
				setTask(
					new Task(orgStore.currentOrg!.tasks.currentTask!, ({ next_id }: XNextTaskRef) => {
						if (next_id) {
							history.replace(generatePath(Paths.OrgTask, { id, taskId: next_id }));
						} else {
							history.replace(generatePath(Paths.OrgTasks, { id }));
						}
					}),
				);
				setFetched(true);
			});
		}, [orgStore, orgId, taskIdParam]);

		function handleBack() {
			history.push(generatePath(Paths.OrgTasks, { id }));
		}

		return (
			<>
				<AppHeader />
				<div className="app__content app-content app-content--sidebar">
					<OrgNavigation path={new Path(["tasks"])} orgId={orgId} />
					<div className="sidebar-content">
						<OrgBreadcrumb id="tasks" />
						<OrgHeader />
						{orgStore.loading || !fetched ? (
							<Overlay global={false} globalWait />
						) : (
							<TaskContent
								task={orgStore.currentOrg!.tasks.currentTask!}
								task1={task!}
								onBack={handleBack}
							/>
						)}
					</div>
				</div>
			</>
		);
	}),
);
