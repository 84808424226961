import React, { useContext, useEffect } from "react";
import cn from "classnames";
import { observer } from "mobx-react-lite";
import { AppHeader } from "@rossotr/components/app-header";

import { StoreContext } from "../../context";
import { generatePath, RouteComponentProps, withRouter } from "react-router";
import { DateTimeFormats, formatDateTime } from "@helpers/datetime";
import { XProcessRef } from "@external-types/process";
import { Paths } from "../../routes/paths";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";
import { TaskState } from "../task";
import { TaskCreation } from "../org-tasks/task-creation";
import { actions } from "../../../../../src/actions";
import { Scope } from "../../constants/external";
import { LightButton } from "../../../../../src/components/buttons/light-button";
import { TaskSchema } from "../../constants/external/task-schema";
// import { ScopeFileList } from "@app-components/files/scope-file-list";

interface ItemProps extends RouteComponentProps {
	item: XProcessRef;
}

const TaskItem = withRouter(
	observer(({ item, history }: ItemProps) => {
		function handleClick() {
			history.push(generatePath(Paths.Task, { taskId: item.id }));
		}

		return (
			<div className="org-lawsuits__row" style={{ width: "100%" }} onClick={handleClick}>
				<div className="org-lawsuits__cell org-lawsuits__cell--primary">{item.scope.name}</div>
				<div className="org-lawsuits__cell">{item.name}</div>
				<div className="org-lawsuits__cell">{item.schema}</div>
				<div className="org-lawsuits__cell">{formatDateTime(item.expire, DateTimeFormats.Until)}</div>
				<div className="org-lawsuits__cell">
					<TaskState task={item} />
				</div>
			</div>
		);
	}),
);

const Content = observer(({ items }: { items: XProcessRef[] }) => {
	return (
		<div className="org-lawsuits">
			<div className="org-lawsuits__caption">
				<div className="org-lawsuits__cell">Организация</div>
				<div className="org-lawsuits__cell">Название</div>
				<div className="org-lawsuits__cell">Тип</div>
				<div className="org-lawsuits__cell">Срок исполнения</div>
				<div className="org-lawsuits__cell">Статус</div>
			</div>

			{items.map(x => (
				<TaskItem key={x.pid} item={x} />
			))}
		</div>
	);
});

export const Tasks = withRouter(
	observer(({ history }: RouteComponentProps) => {
		const { tasksStore } = useContext(StoreContext);
		const [fetched, setFetched] = React.useState(false);
		const [createMode, setCreateMode] = React.useState(false);

		useEffect(() => {
			if (!fetched) {
				tasksStore.applyFilter("schema", TaskSchema.DepartmentalControl).then(() => setFetched(true));
			}
		}, [fetched]);

		function handleFilterApply(filterId: string, value: any) {
			tasksStore.applyFilter(filterId, value);
		}

		function handleShowCreate() {
			setCreateMode(true);
		}

		function handleCancelCreate() {
			setCreateMode(false);
		}

		async function handleCreate({ name, actionId, expire }: { name: string; actionId: number; expire: number }) {
			const processRef = await actions.createScopeProcess(Scope.Archive, actionId, name, expire);
			await tasksStore.fetchPage();
			history.push(generatePath(Paths.Task, { taskId: processRef.id }));
			setCreateMode(false);
		}

		return (
			<>
				<AppHeader />
				<div className="app__content">
					<div style={{ padding: "6.5rem 5rem 6.5rem 6rem" }}>
						<div className="content-header">
							<div className="content-header__title">Ведомственный контроль</div>
						</div>
						{!createMode && (
							<div style={{ display: "flex", alignItems: "center" }}>
								<div style={{ flex: "1 auto" }}>
									<FilterToolbar
										filters={tasksStore.filter}
										onApply={handleFilterApply}
										hiddenFilters={["type", "orderBy"]}
									/>
								</div>

								<div style={{ marginLeft: 40 }}>
									<LightButton primary onClick={handleShowCreate}>
										Создать сбор данных
									</LightButton>
								</div>
							</div>
						)}
						<div className={cn("documents-content")}>
							{createMode ? (
								<TaskCreation onCreate={handleCreate} onCancel={handleCancelCreate} />
							) : (
								<Content items={tasksStore.items} />
							)}
						</div>
					</div>
				</div>
			</>
		);
	}),
);
