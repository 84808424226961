import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { InlineLoader } from "@app-components/controls/inline-loader";
import { ScopePanel } from "@app-components/scope-panel";
import { ContentWithPager } from "@app-components/content-with-pager";
import { BizProductionSection } from "../../models/org-model/org-biz-model";

interface Props {
	section: BizProductionSection;
}

export const BizProductionPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			{section.fetched ? (
				section.sections.map(subSection => (
					<PanelSection title={subSection.scope.name} key={subSection.scope.id}>
						<ContentWithPager section={subSection} />
					</PanelSection>
				))
			) : (
				<PanelSection>
					<InlineLoader />
				</PanelSection>
			)}
		</ScopePanel>
	);
});
