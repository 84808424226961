import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ImagesGallery } from "@app-components/images-gallery";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import { MainSection } from "../../models/org-model/org-object-model";

interface Props {
	section: MainSection;
}

export const MainPanel = observer(({ section }: Props) => {
	const { photoScope, details, properties } = section;

	return (
		<ScopePanel section={section}>
			<PanelSection>
				{properties.map(({ label, value, key }) => (
					<LabelValueRow label={label} value={value} key={key} labelClassName="label-20-perc" />
				))}
			</PanelSection>
			<PanelSection title="Реквизиты">
				{details.map(({ label, value, key }) => (
					<LabelValueRow label={label} value={value} key={key} labelClassName="label-30-perc" />
				))}
			</PanelSection>
			{photoScope !== null && (
				<PanelSection title="Фотографии">
					<ImagesGallery files={photoScope.files} scopeId={photoScope.id} />
				</PanelSection>
			)}
		</ScopePanel>
	);
});
