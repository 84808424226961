import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "@rossotr/components/org-navigation";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";
import { StoreContext } from "../../context";
import { Overlay } from "@app-components/controls/overlay";
import { OrgHeader } from "@rossotr/components/org-header";
import { Path } from "@helpers/navigation";
import { OrgModel } from "@rossotr/models/org-model";
import { DateTimeFormats, formatDateTime } from "@helpers/datetime";
import { Paths } from "../../routes/paths";
import { XProcessRef } from "@external-types/process";
// import { LightButton } from "@app-components/buttons/light-button";
import { TaskCreation } from "./task-creation";
import { actions } from "@actions";
import { TaskState } from "../task";
import { FilterToolbar } from "@app-components/filters/filter-toolbar";

interface ItemProps extends RouteComponentProps {
	item: XProcessRef;
	orgId: number;
}

const TaskItem = withRouter(
	observer(({ item, history, orgId }: ItemProps) => {
		function handleClick() {
			history.push(generatePath(Paths.OrgTask, { id: orgId, taskId: item.id }));
		}

		return (
			<div className="org-lawsuits__row" onClick={handleClick} style={{ width: "100%" }}>
				<div className="org-lawsuits__cell org-lawsuits__cell--primary">{item.name}</div>
				<div className="org-lawsuits__cell">{item.schema}</div>
				<div className="org-lawsuits__cell">{formatDateTime(item.expire, DateTimeFormats.Until)}</div>
				<div className="org-lawsuits__cell">
					<TaskState task={item} />
				</div>
			</div>
		);
	}),
);

const Content = observer(({ org }: { org: OrgModel }) => {
	if (!org.tasks) {
		return null;
	}

	const store = org.tasks;

	function handleFilterApply(filterId: string, value: any) {
		store.applyFilter(filterId, value);
	}

	return (
		<>
			<div>
				<FilterToolbar
					filters={store.filter}
					onApply={handleFilterApply}
					hiddenFilters={["type", "tree", "orderBy"]}
				/>
			</div>
			<div className="org-lawsuits">
				<div className="org-lawsuits__caption">
					<div className="org-lawsuits__cell">Название</div>
					<div className="org-lawsuits__cell">Тип</div>
					<div className="org-lawsuits__cell">Срок исполнения</div>
					<div className="org-lawsuits__cell">Статус</div>
				</div>

				{org.tasks.items!.map(x => (
					<TaskItem key={x.pid} item={x} orgId={org.id} />
				))}
			</div>
		</>
	);
});

interface Props extends RouteComponentProps {}

export const OrgTasks = withRouter(
	observer(function({ history }: Props) {
		const [fetched, setFetched] = React.useState(false);
		const [createMode, setCreateMode] = React.useState(false);
		const { id } = useParams<{ id: string }>();
		const { orgStore } = useContext(StoreContext);
		const orgId = parseInt(id, 10);

		React.useEffect(() => {
			if (!fetched) {
				orgStore.fetchOrgTasks(orgId).then(() => setFetched(true));
			}
		}, [orgStore, orgId]);

		// function handleShowCreate() {
		// 	setCreateMode(true);
		// }

		function handleCancelCreate() {
			setCreateMode(false);
		}

		function handleCreate({ name, actionId, expire }: { name: string; actionId: number; expire: number }) {
			actions.createScopeProcess(orgId, actionId, name, expire).then(({ id }) => {
				history?.replace(generatePath(Paths.OrgTask, { id: orgId, taskId: id }));
			});
		}

		return (
			<>
				<AppHeader />
				<div className="app__content app-content app-content--sidebar">
					<OrgNavigation path={new Path(["tasks"])} orgId={orgId} />
					<div className="sidebar-content">
						<OrgBreadcrumb id="tasks" />
						<OrgHeader>
							{/*{!createMode && (*/}
							{/*	<LightButton primary onClick={handleShowCreate}>*/}
							{/*		Создать сбор данных*/}
							{/*	</LightButton>*/}
							{/*)}*/}
						</OrgHeader>
						{orgStore.loading || !fetched ? (
							<Overlay global={false} globalWait />
						) : createMode ? (
							<TaskCreation onCreate={handleCreate} onCancel={handleCancelCreate} />
						) : (
							<Content org={orgStore.currentOrg!} />
						)}
					</div>
				</div>
			</>
		);
	}),
);
