import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section, TabbedSection } from "@app-models/section";

import { ScopeCode } from "../../constants/external";

export class OrgAgreementsModel extends Section {
	@observable public children: TabbedSection[] = [];

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);
		const targetScope = scopes.find(scope => scope.type.code === ScopeCode.Agreements);

		if (targetScope) {
			runInAction(() => {
				this.children = [new TabbedSection(targetScope, "Заключенные соглашения о сотрудничестве")];
			});
		}

		await super.fetch();
	}
}
