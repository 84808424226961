import React from "react";
import DateTimePicker from "react-datetime";

import { Panel } from "@app-components/panel";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { LightButton } from "@app-components/buttons/light-button";
import { actions } from "@actions";
import { XStartProcessInfo } from "@external-types/process";
import { Overlay } from "@app-components/controls/overlay";
import { XSchemaFormRef } from "@external-types/schema";

interface Props {
	onCancel: () => void;
	onCreate: ({ name, actionId, expire }: { name: string; actionId: number; expire: number }) => void;
}

export function TaskCreation({ onCreate, onCancel }: Props) {
	const [fetched, setFetched] = React.useState(false);
	const [processes, setProcesses] = React.useState<XStartProcessInfo[]>([]);
	const [selectedProcess, setSelectedProcess] = React.useState<XStartProcessInfo | null>(null);
	const [name, setName] = React.useState("");
	const [expirationTime, setExpirationTime] = React.useState(Date.now() + 31104000);

	React.useEffect(() => {
		if (!fetched) {
			actions.getUserTasks().then(processes => {
				setProcesses(processes);
				setFetched(true);

				if (processes.length > 0) {
					setSelectedProcess(processes[0]);
				}
			});
		}
	});

	React.useEffect(() => {
		if (selectedProcess !== null) {
			setExpirationTime(Date.now() + (selectedProcess?.duration ?? 0) * 1000);
		}
	}, [selectedProcess]);

	if (!fetched) {
		return <Overlay global={false} globalWait />;
	}

	function handleAction(action: XSchemaFormRef) {
		return function() {
			onCreate({
				name,
				actionId: action.id,
				expire: expirationTime,
			});
		};
	}

	function handleChangeName(e: React.FormEvent<HTMLInputElement>) {
		setName(e.currentTarget.value);
	}

	function handleProcessChange(e: React.FormEvent<HTMLSelectElement>) {
		setSelectedProcess(processes.find(p => p.id === parseInt(e.currentTarget.value, 10)) ?? null);
	}

	function handleUpdateExpirationTime(e: any) {
		setExpirationTime(e.valueOf());
	}

	const items = processes.map(process => (
		<option key={process.id} value={process.id}>
			{process.name}
		</option>
	));

	return (
		<>
			<Panel header="Создание сбора данных" expandable={false}>
				<PanelSection>
					<div className="task-creation-form">
						<div className="form">
							<div className="form__group">
								<div className="form__row">
									<label className="form__label form__label--required">Тип</label>
									<select
										className="form__input"
										placeholder="Тип"
										value={selectedProcess?.id ?? ""}
										onChange={handleProcessChange}
									>
										{items}
									</select>
								</div>
							</div>

							<div className="form__group">
								<div className="form__row">
									<label className="form__label form__label--required">Название</label>
									<input
										className="form__input"
										type="text"
										onChange={handleChangeName}
										placeholder="Название"
										value={name}
									/>
								</div>
							</div>

							<div className="form__group">
								<div className="form__row">
									<label className="form__label form__label--required">Время завершения</label>
									<DateTimePicker
										value={new Date(expirationTime)}
										locale="ru-Ru"
										inputProps={{ className: "form__input" }}
										onChange={handleUpdateExpirationTime}
										isValidDate={m => m.valueOf() > Date.now()}
										timeConstraints={{ minutes: { min: 0, max: 59, step: 15 } }}
									/>
								</div>
							</div>
						</div>
					</div>

					<div className="task-creation-form__actions">
						<LightButton onClick={onCancel}>Отменить</LightButton>
						{selectedProcess !== null && (
							<div>
								{selectedProcess.actions.map(x => (
									<LightButton primary onClick={handleAction(x)} key={x.id}>
										{x.name}
									</LightButton>
								))}
							</div>
						)}
					</div>
				</PanelSection>
			</Panel>
		</>
	);
}
