import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ScopePanel } from "@app-components/scope-panel";
import { BizResultsSection } from "../../models/org-model/org-biz-model";

interface Props {
	section: BizResultsSection;
}

export const BizResultsPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			<PanelSection>
				{section.items.map(item => {
					const lines = item.results;

					return (
						<React.Fragment key={item.key}>
							<div className="panel-mini-header">Сфера деятельности</div>
							<div className="panel-text">{item.area}</div>
							<div className="panel-fieldset">
								<div className="panel-mini-header">Результаты</div>
								<div className="panel-text">
									{lines.length > 1 ? lines.map((l, index) => <p key={index}>{l}</p>) : item.results}
								</div>
							</div>
						</React.Fragment>
					);
				})}
			</PanelSection>
		</ScopePanel>
	);
});
