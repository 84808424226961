import React from "react";
import { XFileRef } from "@external-types/file";
import { ScopeFileItem } from "@app-components/files/scope-file-item";

interface Props {
	files: XFileRef[];
	scopeId: number;
}

export function ScopeFileList({ files, scopeId }: Props) {
	return (
		<>
			{files.map(file => (
				<ScopeFileItem file={file} scopeId={scopeId} key={file.id} />
			))}
		</>
	);
}
