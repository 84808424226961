import React from "react";
import { observer } from "mobx-react";
import { Section } from "@app-models/section";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { InlineLoader } from "@app-components/controls/inline-loader";
import { Panel } from "@app-components/panel";

interface Props {
	children?: React.ReactNode;
	className?: string;
	section: Section;
	expanded?: boolean;
}

export const ScopePanel = observer(({ section, children, className, expanded = false }: Props) => {
	React.useEffect(() => {
		if (expanded) {
			handleExpanded();
		}
	}, []);

	function handleExpanded() {
		if (!section.fetched) {
			section.fetch();
		}
	}

	return (
		<Panel onExpanded={handleExpanded} header={section.title} className={className} expanded={expanded}>
			{section.fetched ? (
				<>{children}</>
			) : (
				<PanelSection>
					<InlineLoader />
				</PanelSection>
			)}
		</Panel>
	);
});
