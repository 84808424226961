import React from "react";
import { observer } from "mobx-react";

import { PanelSection } from "@app-components/panel-test/panel-section";
import { ContentWithPager } from "@app-components/content-with-pager";
import { ScopePanel } from "@app-components/scope-panel";
import { LabelValueRow } from "@app-components/panel";

import { SpecificationSection } from "../../models/org-model/org-object-model";

interface Props {
	section: SpecificationSection;
}

export const SpecificationPanel = observer(({ section }: Props) => {
	return (
		<ScopePanel section={section}>
			{section.usageInfo.length > 0 && (
				<PanelSection>
					{section.usageInfo.map(({ label, value, key }) => (
						<LabelValueRow
							label={label}
							value={value}
							key={key}
							vertical
							className="org-panel-data-row--bottom-indent-40 "
						/>
					))}
				</PanelSection>
			)}
			{section.currentRenovationScope != null && (
				<PanelSection title={section.currentRenovationScope.scope.name}>
					<ContentWithPager section={section.currentRenovationScope} />
				</PanelSection>
			)}
			{section.previousRenovationScope != null && (
				<PanelSection title={section.previousRenovationScope.scope.name}>
					<ContentWithPager section={section.previousRenovationScope} />
				</PanelSection>
			)}
		</ScopePanel>
	);
});
