import React, { useContext } from "react";
import { useParams } from "react-router";
import { observer } from "mobx-react-lite";

import { Overlay } from "@app-components/controls/overlay";
import { ScopePanel } from "@app-components/scope-panel";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { LabelValueRow } from "@app-components/panel";
import { PanelTabsSection1 } from "@app-components/panel-test/panel-tabs-section";
import { Path } from "@helpers/navigation";

import { AppHeader } from "@rossotr/components/app-header";
import { OrgBreadcrumb, OrgNavigation, SectionItemId } from "@rossotr/components/org-navigation";
import { OrgHeader } from "@rossotr/components/org-header";

import { StoreContext } from "../../context";
import { OrgModel } from "../../models/org-model";

interface Props {
	org: OrgModel;
}

const Content = observer(({ org }: Props) => {
	if (org.orgAgreements === null) {
		return null;
	}

	return (
		<>
			{org.orgAgreements.children.map(child => {
				const attrs = child.getAttributes();
				const hasTabbedData = child.data !== null;

				return (
					<ScopePanel key={child.id} section={child}>
						{attrs.length > 0 && (
							<PanelSection useBottomBorder={hasTabbedData}>
								{attrs.map(attr => (
									<LabelValueRow attr={attr} key={attr.key} />
								))}
							</PanelSection>
						)}
						<PanelSection padding={false}>
							{hasTabbedData && <PanelTabsSection1 data={child.data!} />}
						</PanelSection>
					</ScopePanel>
				);
			})}
		</>
	);
});

export const OrgAgreements = observer(function() {
	const [fetched, setFetched] = React.useState(false);

	const { id } = useParams<{ id: string }>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	async function fetch() {
		await orgStore.fetchOrg(orgId);

		if (orgStore.currentOrg!.orgAgreements !== null) {
			await orgStore.currentOrg!.orgAgreements.fetch();
		}
	}

	React.useEffect(() => {
		fetch().then(() => {
			setFetched(true);
		});
	}, [orgStore, orgId]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["card", SectionItemId.Agreements])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="agreements" />
					<OrgHeader />
					{!fetched ? <Overlay global={false} globalWait /> : <Content org={orgStore.currentOrg!} />}
				</div>
			</div>
		</>
	);
});
