import { observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { AttributeEntry, mapAttributes } from "@helpers/scope-attributes";
import { TabbedControlData, TabGroup } from "@app-models/tabbed-control";
import { Section } from "@app-models/section";

export class PlannedRenovationSection extends Section {
	private scopes: XScopeChildInfo[] = [];
	@observable public content: TabbedControlData | null = null;
	@observable public activeContent: AttributeEntry[] = [];

	public async fetch() {
		const scopeGroups = await actions.getScopeChildren(this.scope.id);

		const groups: TabGroup[] = [];

		for (const scopeGroup of scopeGroups) {
			groups.push({
				groupName: scopeGroup.name,
				key: scopeGroup.id,
				items: mapAttributes(scopeGroup.attrs, scopeGroup.type),
			});
		}

		runInAction(() => {
			this.scopes = scopeGroups;
			this.content = {
				items: groups,
				title: "",
			};
		});

		await super.fetch();
	}

	public fetchScopeContent = (scopeId: number) => {
		if (this.content) {
			const res = this.scopes.find(item => item.id === scopeId);

			if (res) {
				runInAction(() => {
					this.activeContent = mapAttributes(res.attrs, res.type);
				});
				return;
			}
		}

		runInAction(() => {
			this.activeContent = [];
		});
	};

	public get isEmpty() {
		return this.scope == null;
	}
}