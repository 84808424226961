import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { generatePath, RouteComponentProps, useParams, withRouter } from "react-router";

import { Path } from "@helpers/navigation";
import { Overlay } from "@app-components/controls/overlay";
import { Dropdown } from "@app-components/dropdown";
import { PanelSection } from "@app-components/panel-test/panel-section";
import { OrgPanel } from "@app-components/panel-test/org-panel";
import { LightButton } from "@app-components/buttons/light-button";
import { Row } from "@app-components/panel-test/row";
import { Column } from "@app-components/panel-test/column";
import { LabelValueRow } from "@app-components/panel";

import { AppHeader } from "../../components/app-header";
import { OrgBreadcrumb, OrgNavigation } from "../../components/org-navigation";
import { OrgHeader } from "../../components/org-header";
import { OrgObjectsContent, OrgObjectsFilter } from "../../models/org-model/org-objects-model";
import { OrgObjectModel } from "../../models/org-model/org-object-model";
import { StoreContext } from "../../context";
import { Paths } from "../../routes/paths";

interface ButtonProps extends RouteComponentProps {
	objectId: number;
}

const ButtonPanel = withRouter(({ objectId, history }: ButtonProps) => {
	const { id, category1, category2 } = useParams<{ id: string; category1: string; category2: string }>();

	function handleClick() {
		history.push(generatePath(Paths.OrgObjectsCategoryItem, { id, category1, category2, objectId }));
	}

	return (
		<div style={{ display: "flex", justifyContent: "flex-end", marginTop: 20 }}>
			<LightButton onClick={handleClick}>Подробнее</LightButton>
		</div>
	);
});

const OrgObjectCard = observer(({ item }: { item: OrgObjectModel }) => {
	return (
		<OrgPanel title={item.title} className="org-object-card">
			<PanelSection>
				<Row>
					<Column>
						<div className="org-object-card__title">Основное</div>
						{item.mainSection.properties.map(({ label, value, key }) => (
							<LabelValueRow label={label} value={value} key={key} />
						))}
					</Column>
					<Column>
						<PanelSection>
							<div className="org-object-card__title">Реквизиты</div>
							{item.mainSection.details.map(({ label, value, key }) => (
								<LabelValueRow label={label} value={value} key={key} />
							))}
						</PanelSection>
					</Column>
				</Row>
				<ButtonPanel objectId={item.id} />
			</PanelSection>
		</OrgPanel>
	);
});

const Content = observer(({ content }: { content: OrgObjectsContent }) => {
	function handleFilterChange(filter: OrgObjectsFilter) {
		return function(value: number) {
			content.onFilterChange(filter, value);
		};
	}

	return (
		<>
			<div className="org-objects-filter-bar">
				{content.filters.map(filter => (
					<Dropdown
						key={filter.id}
						items={filter.options}
						value={filter.value}
						onChange={handleFilterChange(filter)}
					/>
				))}
			</div>
			<div>
				{content.items.map(item => (
					<OrgObjectCard item={item} key={item.id} />
				))}
			</div>
		</>
	);
});

export const OrgObjects = observer(function() {
	const [fetched, setFetched] = React.useState(false);
	const { id, category1, category2 } = useParams<{
		id: string;
		category1: string;
		category2: string;
	}>();
	const { orgStore } = useContext(StoreContext);
	const orgId = parseInt(id, 10);

	React.useEffect(() => {
		setFetched(false);

		orgStore.fetchObjects(orgId, parseInt(category1, 10), parseInt(category2, 10)).then(() => {
			setFetched(true);
		});
	}, [orgStore, orgId, category1, category2]);

	return (
		<>
			<AppHeader />
			<div className="app__content app-content app-content--sidebar">
				<OrgNavigation path={new Path(["objects", category1, category2])} orgId={orgId} />
				<div className="sidebar-content">
					<OrgBreadcrumb id="objects" />
					<OrgHeader />
					<div className="sidebar-content__sub-header">Объекты</div>
					{orgStore.loading || !fetched ? (
						<Overlay global={false} globalWait />
					) : (
						<Content content={orgStore.currentOrg!.objects.content!} />
					)}
				</div>
			</div>
		</>
	);
});
