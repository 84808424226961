import { observable, runInAction } from "mobx";
import { actions } from "@actions";
import { XScopeChildInfo } from "@external-types/scope";
import { TabbedControlData, TabGroup } from "@app-models/tabbed-control";
import { Section } from "@app-models/section";
import { mapAttributes } from "@helpers/scope-attributes";
import { SectionContentWithPager } from "@app-models/section-content-with-pager";

export class RoomInfoSection extends Section {
	public scopes: XScopeChildInfo[] = [];
	@observable public content: TabbedControlData | null = null;
	@observable public activeContent: SectionContentWithPager | null = null;

	public async fetch() {
		if (this.scope) {
			const scopeGroups = await actions.getScopeChildren(this.scope.id);

			const groups: TabGroup[] = [];

			for (const scopeGroup of scopeGroups) {
				groups.push({
					groupName: scopeGroup.name,
					key: scopeGroup.id,
					items: mapAttributes(scopeGroup.attrs, scopeGroup.type),
				});
			}

			runInAction(() => {
				this.scopes = scopeGroups;
				this.content = {
					items: groups,
					title: "",
				};
			});
		}

		await super.fetch();
	}

	public fetchScopeContent = async (scopeId: number) => {
		const activeContent = new SectionContentWithPager(this.scopes.find(scope => scope.id == scopeId)!);
		await activeContent.fetch();

		runInAction(() => {
			this.activeContent = activeContent;
		});
	};
}