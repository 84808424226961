import { action, computed, observable, reaction, runInAction } from "mobx";
import { actions } from "@actions";

import { getDefaultExpireTimestamp, isExpired } from "@helpers/datetime";
import { XUserRef } from "@external-types/user";
import { XScopeCard } from "@external-types/scope";
import { XActionType, XHistoryItem, XProcessRef, XProcessTaskInfo, XTaskAction } from "@external-types/process";
import { XSchemaFormItemInfo } from "@external-types/schema";
import { XSubprocessType } from "@external-types/schema/schema-form-info";
import { XNextTaskRef } from "@external-types/process/next-task-ref";
import { SchemaFieldType, ISchemaForm, SchemaForm } from "@app-models/schema-form";
import { DataSourceSession, InfiniteDataSource } from "@app-models/data-source";
import { IDataSource, IDataSourceSession } from "@app-types/data";
import { OptionItem } from "@app-types/models";

export interface ITask {
	actionDeadlineTimestamp: number | null;
	actionExecutors: XUserRef[];
	actionIsExecuting: boolean;
	actionOptions: XTaskAction[];
	actions: XTaskAction[];
	scopeDataSource: IDataSource<OptionItem<number>>;
	form: ISchemaForm;
	selectedActionScopes: number[];
	selectedExecutors: number[];
	submitAllowed: boolean;
	actionId: number;

	isActionHasExecutors(action: XTaskAction): boolean;
	isActionSubprocessRequired(action: XTaskAction): boolean;
	runAction(): Promise<any>;
	updateActionId(actionId: number): void;
	updateActionScopes(actionScopes: number[]): void;
	updateDeadlineTimestamp(timestamp: number): void;
	updateExecutors(executors: number[]): void;
}

class ScopeDataSource extends InfiniteDataSource<OptionItem<number>> {
	protected session: IDataSourceSession<OptionItem<number>>;
	@observable public taskId: number;
	@observable public actionId: number | null = null;

	public constructor(taskId: number) {
		super();

		this.taskId = taskId;
		this.session = new DataSourceSession();
	}

	protected async fetch(): Promise<Array<OptionItem<number>>> {
		if (this.actionId) {
			const pageInfo = await actions.getTaskScopes(this.taskId, this.actionId, this.pagination);

			this.updatePageInfo(pageInfo);

			return pageInfo.list.map(x => ({ label: x.name, value: x.id }));
		}

		return [];
	}

	public fetchByAction(actionId: number) {
		this.actionId = actionId;
		this.fetchPage();
	}

	@action
	public clear() {
		this.session.items = [];
	}
}

export class Task implements ITask {
	public id!: number;
	public name!: string;
	public schema!: string;
	public expire!: number;
	public initiator!: XUserRef;
	public completed!: boolean;
	public expired!: boolean;
	public scope!: XScopeCard;
	public pid!: number;

	@observable public state!: string;

	@observable.ref public actions: XTaskAction[] = [];
	@observable.ref public actionOptions: XTaskAction[] = [];
	@observable.ref public history: XHistoryItem[] = [];
	@observable.ref public form!: ISchemaForm;
	@observable public actionId: number = -1;
	@observable public isInfoVisible: boolean = false;

	@observable.ref public actionExecutors: XUserRef[] = [];
	@observable public actionDeadlineTimestamp: number | null = null;
	@observable public selectedExecutors: number[] = [];
	@observable public actionIsExecuting: boolean = false;
	@observable public selectedActionScopes: number[] = [];
	public scopeDataSource: ScopeDataSource;

	public constructor(taskInfo: XProcessTaskInfo, private onAction: (nextTaskRef: XNextTaskRef) => void) {
		this.scopeDataSource = new ScopeDataSource(taskInfo.id);
		this.updateByTaskInfo(taskInfo);

		reaction(
			() => this.actionId,
			(actionId: number) => {
				if (actionId === -1) {
					this.actionExecutors = [];
				} else {
					const action = this.actions.find(x => x.actionID === actionId)!;

					if (this.isActionHasExecutors(action)) {
						actions.getAllTaskActionExecutors(this.id, actionId).then(result => {
							runInAction(() => {
								this.actionExecutors = result;
							});
						});
						this.actionDeadlineTimestamp = getDefaultExpireTimestamp(action.duration);
					} else {
						this.actionExecutors = [];
					}

					if (this.isActionSubprocessRequired(action)) {
						this.scopeDataSource.fetchByAction(actionId);
					} else {
						this.scopeDataSource.clear();
					}
				}

				this.selectedExecutors = [];
			},
		);
	}

	@action
	public updateExecutors(executors: number[]) {
		this.selectedExecutors = executors;
	}

	@action
	public updateActionScopes(actionScopes: number[]) {
		this.selectedActionScopes = actionScopes;
	}

	@action
	public async toggleInfoVisible() {
		await this.fetchScope();

		runInAction(() => {
			this.isInfoVisible = !this.isInfoVisible;
		});
	}

	@action
	public updateDeadlineTimestamp(timestamp: number) {
		this.actionDeadlineTimestamp = timestamp;
	}

	@action
	public updateActionIsExecuting(isExecuting: boolean) {
		this.actionIsExecuting = isExecuting;
	}

	public isActionHasExecutors(action: XTaskAction) {
		return action.type === XActionType.Assigned || action.type === XActionType.Parallel;
	}

	public isActionSubprocessRequired(action: XTaskAction) {
		return action.type === XActionType.Subprocess && action.subprocess.type !== XSubprocessType.All;
	}

	@action
	private updateByProcessRef(processRef: XProcessRef) {
		const expired = isExpired(processRef.expire);

		this.id = processRef.id;
		this.pid = processRef.pid;
		this.name = processRef.name;
		this.schema = processRef.schema;
		this.state = processRef.state;
		this.expire = processRef.expire;
		this.completed = processRef.is_finish && !expired;
		this.expired = !processRef.is_finish && expired;
		this.initiator = processRef.initiator;
		this.scope = processRef.scope as XScopeCard;
	}

	private async fetchScope() {
		const scope = await actions.getScope(this.scope.id);

		runInAction(() => {
			this.scope = scope;
		});
	}

	public async substitute(id: number) {
		runInAction(() => {
			this.id = id;
			this.actionId = -1;
		});

		this.fetch();
	}

	public async runAction() {
		if (this.form.validate()) {
			const nextTaskRef = await this.runActionInternal();

			this.onAction(nextTaskRef);
		}
	}

	@action
	public updateByTaskInfo(taskInfo: XProcessTaskInfo) {
		this.updateByProcessRef(taskInfo);
		this.actions = taskInfo.actions;
		this.actionOptions = taskInfo.actions;

		const baseHistory = taskInfo.base ? taskInfo.base.history : [];

		this.createForm(taskInfo.items);
		this.history = taskInfo.history.reverse().concat(baseHistory.reverse());
	}

	public async fetch() {
		const taskInfo = await actions.getTask(this.id);

		this.updateByTaskInfo(taskInfo);
	}

	private async runActionInternal(): Promise<XNextTaskRef> {
		const action = this.actions.find(x => x.actionID === this.actionId)!;
		const hasExecutors = this.isActionHasExecutors(action);
		const subprocessRequired = this.isActionSubprocessRequired(action);

		const data = {
			items: this.form.fields
				.filter(f => f.type !== SchemaFieldType.Label)
				.map(x => ({ id: x.id, value: x.value || undefined, files: x.files })),
			accounts: hasExecutors ? this.selectedExecutors : undefined,
			scopes: subprocessRequired ? this.selectedActionScopes : undefined,
			expire: this.actionDeadlineTimestamp != null ? this.actionDeadlineTimestamp : undefined,
			// TODO: should be selected on UI
			subform:
				subprocessRequired && action.subprocess.forms.length > 0 ? action.subprocess.forms[0].id : undefined,
		};

		try {
			this.updateActionIsExecuting(true);
			return await actions.runTaskAction(this.id, this.actionId, data);
		} finally {
			this.updateActionIsExecuting(false);
		}
	}

	@action
	public updateActionId(actionId: number) {
		this.actionId = actionId;
		// this.detailsVisible = false;
	}

	@computed
	public get submitAllowed() {
		const action = this.actions.find(x => x.actionID === this.actionId);

		if (action) {
			if (action.type === XActionType.Parallel && this.selectedExecutors.length !== action.parallel.count) {
				return false;
			}
			if (action.type === XActionType.Assigned && this.selectedExecutors.length === 0) {
				return false;
			}
			if (
				action.type === XActionType.Subprocess &&
				action.subprocess.type !== XSubprocessType.All &&
				this.selectedActionScopes.length === 0
			) {
				return false;
			}
		}
		return true;
	}

	@action
	private createForm(items: XSchemaFormItemInfo[]) {
		this.form = new SchemaForm(items);
	}
}
