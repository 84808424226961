import { XScopeRef } from "@external-types/scope";
import { actions } from "@actions";
import { action, computed, observable, runInAction } from "mobx";
import { OptionItem } from "@app-types/models";
import { OrgObjectModel } from "@rossotr/models/org-model/org-object-model";

export class OrgObjectsFilter {
	@observable public value: number;

	public constructor(private scopeId: number, private scopeRefs: XScopeRef[], public level: number) {
		this.value = scopeRefs[0].id;
	}

	@action
	public updateValue(value: number) {
		this.value = value;
	}

	public get id() {
		return this.scopeId;
	}

	@computed
	public get options(): Array<OptionItem<number>> {
		return this.scopeRefs.map(ref => ({
			label: ref.name,
			value: ref.id,
		}));
	}
}

export class OrgObjectsContent {
	@observable public filters: OrgObjectsFilter[] = [];
	@observable public items: OrgObjectModel[] = [];

	public constructor(private scopeRef: XScopeRef) {}

	public get activeScopeId() {
		if (this.filters.length === 0) {
			return this.scopeRef.id;
		}

		return this.filters[this.filters.length - 1].value;
	}

	public onFilterChange(filter: OrgObjectsFilter, value: number) {
		filter.updateValue(value);

		runInAction(() => {
			this.filters.splice(filter.level + 1, this.filters.length - 1 - filter.level);
			this.items = [];
		});

		return this.fetch();
	}

	public async fetch() {
		const scopeId = this.activeScopeId;
		const children = await actions.getScopeChildren(scopeId);

		if (children.length > 0) {
			if (children[0].attrs.length > 0) {
				runInAction(() => {
					// @ts-ignore
					this.items = children.map(ch => new OrgObjectModel(ch));
				});

			} else {
				runInAction(() => {
					this.filters.push(new OrgObjectsFilter(scopeId, children, this.filters.length));
				});
				await this.fetch();
			}
		}
	}
}

export class OrgObjectsModel {
	private _scopeRef: XScopeRef | null = null;
	private sectionScopeRefs: XScopeRef[] = [];
	private readonly subSectionScopeRefs: Map<number, XScopeRef[]> = new Map();
	@observable public content: OrgObjectsContent | null = null;
	@observable public currentObject: OrgObjectModel | null = null;

	public async init(scopeRef: XScopeRef) {
		this._scopeRef = scopeRef;
		this.sectionScopeRefs = await actions.getScopeChildren(this.scopeRef.id);

		for (const sectionScopeRef of this.sectionScopeRefs) {
			const subSectionScopeRefs = await actions.getScopeChildren(sectionScopeRef.id);

			this.subSectionScopeRefs.set(sectionScopeRef.id, subSectionScopeRefs);
		}
	}

	public get sections(): XScopeRef[] {
		return this.sectionScopeRefs;
	}

	public getSubsections(sectionScopeId: number): XScopeRef[] {
		return this.subSectionScopeRefs.get(sectionScopeId) ?? [];
	}

	public get scopeRef(): XScopeRef {
		if (!this._scopeRef) {
			throw new Error("Object model is not initialized");
		}

		return this._scopeRef;
	}

	public async fetchObject(objectId: number) {
		runInAction(() => {
			this.currentObject = null;
		});

		const scope = await actions.getScope(objectId);
		const object = new OrgObjectModel(scope);
		await object.fetch();

		runInAction(() => {
			this.currentObject = object;
		});
	}

	public async fetch(sectionScopeId: number, subsectionScopeId: number) {
		const scopeRef = this.getSubsections(sectionScopeId).find(s => s.id === subsectionScopeId);

		if (scopeRef) {
			const content = new OrgObjectsContent(scopeRef);

			await content.fetch();

			runInAction(() => {
				this.content = content;
			});
		}
	}
}