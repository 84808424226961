import { OrgsSession } from "./session/orgs-session";
import { OrgSession } from "./session/org-session";
import { DocumentsSession } from "./session/documents-session";
import { TasksSession } from "./session/tasks-session";
import { IAppState } from "@app-types/stores";
import { ArchiveSession } from "./session/archive-session";

export class AppState implements IAppState {
	public orgs: OrgsSession = new OrgsSession();
	public org: OrgSession = new OrgSession();
	public documents: DocumentsSession = new DocumentsSession();
	public tasks: TasksSession = new TasksSession();
	public archive: ArchiveSession = new ArchiveSession();

	public clean() {
		this.orgs = new OrgsSession();
		this.org = new OrgSession();
		this.documents = new DocumentsSession();
		this.tasks = new TasksSession();
		this.archive = new ArchiveSession();
	}

	public init() {
		this.clean();
	}
}
