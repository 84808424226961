import { Section } from "@app-models/section";
import { XScopeChildInfo } from "@app-types/external-data/scope";
import { breakByLine } from "@helpers/text";

export class BizOtherInfoSection extends Section {
	public value: string[] = [];

	public constructor(scope: XScopeChildInfo) {
		super(scope);

		if (scope.attrs.length > 0) {
			this.value = breakByLine(scope.attrs[0].value);
		}
	}
}
