import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section } from "@app-models/section";
import { SectionContentWithPager } from "@app-models/section-content-with-pager";
import { getScopeAttributeValue, mapAttributes } from "@helpers/scope-attributes";
import { XScopeChildInfo } from "@external-types/scope";
import { TabbedControlData } from "../../../../../../src/models/tabbed-control";

export class BizEducationSubSection extends Section {
	public contentMap: Map<number, SectionContentWithPager> = new Map();
	@observable public activeContentScopeId: number = 0;
	value: TabbedControlData | null = null;

	public async fetch() {
		const groupScopes = await actions.getScopeChildren(this.id);

		const groups = [];

		for (const groupScopeInfo of groupScopes) {
			const subGroupScopes = await actions.getScopeChildren(groupScopeInfo.id);
			const subGroups = subGroupScopes.map(x => ({
				groupName: x.name,
				key: x.id,
				items: mapAttributes(x.attrs, x.type),
			}));

			groups.push({
				groupName: groupScopeInfo.name,
				key: groupScopeInfo.id,
				disabled: true,
				items: subGroups,
			});
		}

		this.value = {
			title: this.title,
			items: groups,
		};

		await super.fetch();
	}

	public fetchContent = async (scopeId: number) => {
		const scope = await actions.getScope(scopeId);

		const sectionContent = new SectionContentWithPager(scope);
		await sectionContent.fetch();

		this.contentMap.set(scopeId, sectionContent);

		runInAction(() => {
			this.activeContentScopeId = scopeId;
		});
	};

	public getContent(scopeId: number) {
		if (this.contentMap.has(scopeId)) {
			return this.contentMap.get(scopeId);
		}
	}
}

export class BizEducationInfoSection {
	public constructor(private scope: XScopeChildInfo) {}

	public get title() {
		return this.scope.name;
	}

	public get studentsBeforeSchool() {
		return getScopeAttributeValue(this.scope!, 1604);
	}

	public get groupsBeforeSchool() {
		return getScopeAttributeValue(this.scope!, 1605);
	}

	public get studentsBeginnerSchool() {
		return getScopeAttributeValue(this.scope!, 1606);
	}

	public get groupsBeginnerSchool() {
		return getScopeAttributeValue(this.scope!, 1607);
	}

	public get studentsCommonSchool() {
		return getScopeAttributeValue(this.scope!, 1608);
	}

	public get groupsCommonSchool() {
		return getScopeAttributeValue(this.scope!, 1609);
	}

	public get studentsMiddleSchool() {
		return getScopeAttributeValue(this.scope!, 1610);
	}

	public get groupsMiddleSchool() {
		return getScopeAttributeValue(this.scope!, 1611);
	}
}

export class BizEducationSection extends Section {
	@observable public sections: SectionContentWithPager[] = [];
	@observable public baseSection: BizEducationSubSection | null = null;
	@observable public additionalSection: BizEducationSubSection | null = null;
	@observable public infoSection: BizEducationInfoSection | null = null;

	public async fetch() {
		const rootScopes = await actions.getScopeChildren(this.id);

		for (const rootScope of rootScopes) {
			switch (rootScope.type.code) {
				case "BIZ121":
					const baseScopes = await actions.getScopeChildren(rootScope.id);
					for (const baseScope of baseScopes) {
						switch (baseScope.type.code) {
							case "BIZ1212":
								const baseSection = new BizEducationSubSection(baseScope);
								await baseSection.fetch();

								runInAction(() => {
									this.baseSection = baseSection;
								});
								break;
							case "BIZ1211":
								runInAction(() => {
									this.infoSection = new BizEducationInfoSection(baseScope);
								});
								break;
						}
					}
					break;
				case "BIZ122": {
					const additionalSection = new BizEducationSubSection(rootScope);
					await additionalSection.fetch();

					runInAction(() => {
						this.additionalSection = additionalSection;
					});

					break;
				}
			}
		}

		await super.fetch();
	}
}
