import { observable, runInAction } from "mobx";

import { actions } from "@actions";
import { Section } from "@app-models/section";
import { getScopeAttributeValue } from "@helpers/scope-attributes";
import { breakByLine } from "@helpers/text";

interface ResultItem {
	key: number;
	area: string;
	results: string[];
}

export class BizResultsSection extends Section {
	@observable public items: ResultItem[] = [];

	public async fetch() {
		const scopes = await actions.getScopeChildren(this.id);

		runInAction(() => {
			this.items = scopes.map(scope => ({
				key: scope.id,
				area: getScopeAttributeValue(scope, 5434),
				results: breakByLine(getScopeAttributeValue(scope, 5435)),
			}));
		});

		await super.fetch();
	}
}
